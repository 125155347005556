<template>
  <div>
    <section
      v-for="(section, index) in sections"
      :key="'section-' + index"
      :id="$root.slugify(section.label)"
      class="section"
      :class="[
        'section--' + section.acf_fc_layout,
        'section--' + (index + 1),
        { 'section--no-header': !section.label && !section.title },
      ]"
    >
      <!-- Heading -->
      <div
        v-if="section.acf_fc_layout == 'heading'"
        class="section__wrapper"
        :data-section-index="index"
      >
        <h4
          v-html="section.label"
          v-if="section.label"
          class="section__label"
        ></h4>
        <h1
          v-html="$root.addBreakLines(section.title)"
          v-if="section.title"
          class="section__title"
        ></h1>
        <WpLink
          v-if="section.button"
          :link="section.button"
          class="section__button"
        ></WpLink>
      </div>

      <!-- Content -->
      <div
        v-if="section.acf_fc_layout == 'content'"
        class="section__wrapper section__wrapper--full"
        :data-section-index="index"
      >
        <h4
          v-html="section.label"
          v-if="section.label"
          class="section__label"
        ></h4>
        <h1
          v-html="$root.addBreakLines(section.title)"
          class="section__title"
          v-if="section.title"
        ></h1>
        <WpRichText
          v-if="section.rich_text"
          :field="section.rich_text"
          class="section__rich-text"
        ></WpRichText>
      </div>

      <!-- Image -->
      <div
        v-if="section.acf_fc_layout == 'image'"
        class="section__wrapper section__wrapper--no-margin"
        :class="['section__wrapper--' + section.style]"
        :data-section-index="index"
      >
        <!-- {'section__wrapper--no-margin-page' : index == 0 && !section.label && !section.title} -->
        <h4
          v-html="section.label"
          v-if="section.label"
          class="section__label"
        ></h4>
        <h1
          v-html="$root.addBreakLines(section.title)"
          class="section__title"
          v-if="section.title"
        ></h1>
        <ResponsiveImageVW
          class="section__cover"
          :wpImageObject="section.image"
          type="background"
        ></ResponsiveImageVW>
      </div>

      <!-- Video -->
      <div
        v-if="section.acf_fc_layout == 'video'"
        class="section__wrapper section__wrapper--no-margin"
        :class="['section__wrapper--' + section.style]"
        :data-section-index="index"
      >
        <h4
          v-html="section.label"
          v-if="section.label"
          class="section__label"
        ></h4>
        <h1
          v-html="$root.addBreakLines(section.title)"
          class="section__title"
          v-if="section.title"
        ></h1>
        <div class="wp-block-embed__wrapper " v-html="section.video"></div>
      </div>

      <!-- Gallery -->
      <div
        v-if="section.acf_fc_layout == 'gallery'"
        class="section__wrapper section__wrapper--no-margin"
        :data-section-index="index"
      >
        <h4
          v-html="section.label"
          v-if="section.label"
          class="section__label"
        ></h4>
        <h3
          v-html="$root.addBreakLines(section.title)"
          v-if="section.title"
          class="section__title"
        ></h3>
        <Slider :setting="$root.gallerySetting" class="section__gallery">
          <ResponsiveImageVW
            v-for="(gallery, galleryIndex) in section.gallery"
            :key="'gallery-' + galleryIndex"
            :wpImageObject="gallery"
            class="section__gallery-image"
          ></ResponsiveImageVW>
        </Slider>
        <WpRichText
          v-if="section.rich_text"
          :field="section.rich_text"
          class="section__rich-text"
        ></WpRichText>
      </div>

      <!-- Links -->
      <div
        v-if="section.acf_fc_layout == 'links'"
        class="section__wrapper"
        :data-section-index="index"
      >
        <h4
          v-html="section.label"
          v-if="section.label"
          class="section__label"
        ></h4>
        <h2
          v-html="$root.addBreakLines(section.title)"
          v-if="$root.addBreakLines(section.title)"
          class="section__title"
        ></h2>
        <div
          class="section__links"
          :class="['section__links--' + section.links.length]"
        >
          <div
            class="section__link"
            v-for="(link, indexLink) in section.links"
            :key="'link-' + indexLink"
          >
            <Thumb :link="link"></Thumb>
          </div>
        </div>
        <WpLink
          v-if="section.button"
          :link="section.button"
          class="section__button"
        ></WpLink>
      </div>

      <!-- Grid -->
      <div
        v-if="section.acf_fc_layout == 'grids'"
        class="section__wrapper"
        :data-section-index="index"
      >
        <h4
          v-html="section.label"
          v-if="section.label"
          class="section__label"
        ></h4>
        <h2
          v-html="$root.addBreakLines(section.title)"
          v-if="section.title"
          class="section__title"
        ></h2>
        <div
          v-if="section.grids && section.grids[0]"
          class="section__grid"
          :class="['section__grid--' + section.grids.length]"
        >
          <div
            class="section__grid-element"
            v-for="(grid, indexGrid) in section.grids"
            :key="'grid-' + indexGrid"
            :class="['section__grid-element--' + grid.background_color]"
          >
            <h6
              v-html="grid.label"
              v-if="grid.label"
              class="section__label"
            ></h6>
            <h3
              v-html="grid.title"
              v-if="grid.title"
              class="section__title"
            ></h3>
            <ResponsiveImageVW
              v-if="grid.image !== false"
              class="section__image"
              :wpImageObject="grid.image"
            ></ResponsiveImageVW>
            <!-- <ResponsiveImage class="section__image" :src="grid.image"></ResponsiveImage> -->
            <WpRichText
              v-if="grid.rich_text"
              :field="grid.rich_text"
              class="section__rich-text"
            ></WpRichText>
          </div>
        </div>
      </div>

      <!-- Lists -->
      <div
        v-if="section.acf_fc_layout == 'lists'"
        class="section__wrapper section__columns"
        :data-section-index="index"
      >
        <div
          class="section__list"
          v-for="(list, indexList) in section.lists"
          :key="'list-' + indexList"
        >
          <h4 v-html="list.label" v-if="list.label" class="section__label"></h4>
          <h2
            v-html="$root.addBreakLines(list.title)"
            v-if="list.title"
            class="section__title"
          ></h2>
          <div
            class="section__list-element"
            v-for="(element, indexList) in list.elements"
            :key="'list-' + indexList"
          >
            <h6
              v-html="$root.addBreakLines(element.title)"
              v-if="element.title"
              class="section__title"
            ></h6>
            <h3
              v-html="element.subtitle"
              v-if="element.subtitle"
              class="section__subtitle"
            ></h3>
            <WpLink
              v-if="element.link"
              :link="element.link"
              class="section__link"
            ></WpLink>
          </div>
        </div>
      </div>

      <!-- Newsletter -->
      <div
        v-if="section.acf_fc_layout == 'newsletter'"
        class="section__wrapper"
        :data-section-index="index"
      >
        <h4
          v-html="section.label"
          v-if="section.label"
          class="section__label"
        ></h4>
        <h2
          v-html="$root.addBreakLines(section.title)"
          v-if="section.title"
          class="section__title"
        ></h2>
        <div class="section__newsletter">
          <form
            action="https://lafil.us19.list-manage.com/subscribe/post?u=cb3382536aa50a3ddde26521e&amp;id=8cb6fa8fc1"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            target="_blank"
            novalidate
          >
            <!-- <label for="mce-EMAIL">Subscribe</label> -->
            <input
              type="email"
              value=""
              name="EMAIL"
              class="email"
              id="mce-EMAIL"
              :placeholder="$labels('latuamail')"
              required
            />
            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <!-- <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_cb3382536aa50a3ddde26521e_8cb6fa8fc1" tabindex="-1" value=""></div> -->
            <button
              type="submit"
              value="Invia"
              name="subscribe"
              id="mc-embedded-subscribe"
            >
              ↗
            </button>
          </form>
        </div>
      </div>

      <!-- Archive -->
      <div
        v-if="section.acf_fc_layout == 'archive'"
        class="section__wrapper"
        :class="['section__wrapper--' + section.style]"
        :data-section-index="index"
      >
        <h4 v-html="section.label" v-if="section.label"></h4>
        <h2
          v-html="$root.addBreakLines(section.title)"
          :class="'section__title--' + section.title_size"
          v-if="section.title"
        ></h2>
        <Archive :data="section" />
      </div>
    </section>
  </div>
</template>

<script>
// import ResponsiveImage from '@/components/utility/ResponsiveImage'
import ResponsiveImageVW from "@/components/utility/ResponsiveImageVW";
import Slider from "@/components/utility/Slider";
import WpRichText from "@/components/utility/WpRichText";
import WpLink from "@/components/utility/WpLink";
import Thumb from "@/components/template-parts/Thumb";
import Archive from "@/components/archive";

export default {
  name: "PageBuilder",
  components: {
    // ResponsiveImage,
    ResponsiveImageVW,
    Slider,
    WpRichText,
    WpLink,
    Thumb,
    Archive,
  },
  props: {
    sections: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    if (!this.$el) {
      return;
    }
    this.$el
      .querySelectorAll(".section__grid-element a")
      .forEach((gridElement) => {
        const enterHandler = (event) => {
          event.target.closest(".section__grid-element").classList.add("hover");
        };
        gridElement.addEventListener("mouseenter", enterHandler);
        const leaveHandler = (event) => {
          event.target
            .closest(".section__grid-element")
            .classList.remove("hover");
        };
        gridElement.addEventListener("mouseleave", leaveHandler);
      });
  },
  methods: {},
};
</script>

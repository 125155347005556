import Vue from "vue";
import Router from "vue-router";

import store from "@/store";
import routes from "./routes";

const { url } = __VUE_WORDPRESS__.routing;
const { i18n } = __VUE_WORDPRESS__.state;

// scroll position is handled in @after-leave transition hook
if ("scrollRestoration" in window.history)
  window.history.scrollRestoration = "manual";

Vue.use(Router);

const router = new Router({
  base: process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : "/",
  mode: "history",
  routes: routes,
});

router.beforeEach((to, from, next) => {
  store.commit("SET_CURRENT_POST", null);
  store.commit("SET_FROM", from);
  const lang = to.params.lang ? to.params.lang : i18n ? i18n.default : "it";
  store.commit("SET_LANG", lang);

  if (from.meta && from.meta.isParentWithOverlay === true) {
    router.app.$root.parentWithOverlay = from.name;
  }
  next();
});

export default router;

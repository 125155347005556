import axios from 'axios'

let { url } = __VUE_WORDPRESS__.routing

url = url.replace('8888', '3000')

const urlWp = `${url}/wp-json/wp/v2/`;
const urlWCM = `${url}/wp-json/gds/v1/woo-commerce-manager/`;
const urlCoCart = `${url}/wp-json/cocart/v1/`;

const ajax = axios.create(
  {
    baseURL: urlWp,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
)

const ajaxWooCommerce = axios.create(
  {
    baseURL: urlWCM,
    // headers: {
    //   'Accept': 'application/json',
    //   'Content-Type': 'application/json'
    // },
  }
)

const ajaxCoCart = axios.create(
  {
    baseURL: urlCoCart,
    // headers: {
    //   'Accept': 'application/json',
    //   'Content-Type': 'application/json'
    // },
  }
)

const batchRequest = {}

function addBatchId(type, id) {
  if ( ! batchRequest[type] ) {
    batchRequest[type] = {}
    batchRequest[type].ids = [id]
    batchRequest[type].request = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(batchRequestIds(type))
        batchRequest[type] = null
      }, 100)
    })
  } else if( ! batchRequest[type].ids.includes(id) ){
    batchRequest[type].ids.push(id)
  }
}

function batchRequestIds(type) {
  return ajax.get(`/${type}/`, { params: { include: batchRequest[type].ids, per_page: 100 } })
}

export const fetchSingleById = ({ type, id, batch = false }) => {
  if (batch) {
    addBatchId(type, id)
    return batchRequest[type].request
  } else {
    return ajax.get(`/${type}/${id}`)
  }
}

export const fetchSingle = ({ type, params = {} }) => {
  return ajax.get(`/${type}/`, { params })
}

export const fetchItems = ({ type, params = {} }) => {
  return ajax.get(`/${type}/`, { params })
}

export const fetchWooCommerceItems = ({ type, params = {} }) => {
  return ajaxWooCommerce.get(`/${type}/`, { params })
}

export const addToCart = (body, cookies) => {
  cookies.set('lafil-cart', body.id, 0);
  const { name, price_html } = body
  cookies.set('lafil-cart-product', {
    name,
    price_html
  }, 0);
  // return axios.post(`${urlCoCart}add-item`, body, {
  // })
}

export const getCartContent = (cookies) => {
  return cookies.get('lafil-cart');
  // return axios.get(`${urlCoCart}get-cart/`, {
  // })
}

export const emptyCart = (cookies) => {
  cookies.remove('lafil-cart')
  cookies.remove('lafil-cart-product')
}

export const createOrder = (body) => {
  return axios.post(`${urlWCM}orders/`, body, {
  })
}

export const createPaymentIntent = (body) => {
  return axios.post(`${urlWCM}payment_intents/`, body, {
  })
}

export const fetchPrivacyPolicy = () => {
  return axios.get(`https://www.iubenda.com/api/privacy-policy/66551943`, {
  })
}
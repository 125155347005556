<template>
  <header
    class="site-header"
    :class="{
      'site-header--open': isVisible,
    }"
  >
    <div class="site-header__header">
      <a class="site-header__toggle" @click="toggleNav()">
        <div class="site-header__menu-icon">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class="site-header__menu-label" v-if="isVisible">
          {{ $labels("chiudi") }}
        </div>
        <div class="site-header__menu-label" v-else>{{ $labels("menu") }}</div>
      </a>
      <router-link class="site-header__branding" :to="home">
        <div class="logo logo--black" :alt="site.description"></div>
      </router-link>
      <router-link class="site-header__fast-link" :to="perLaFil">{{
        $labels("tu_per_la_fil")
      }}</router-link>
    </div>
    <div
      class="site-header__wrapper"
      ref="wrapper"
      :class="{ 'site-header__wrapper--visible': isVisible }"
      :style="{ height: height }"
    >
      <div class="site-header__body">
        <NavMenu class="site-header__menu" name="main" />
        <div class="site-header__contacts">
          <div>
            <div class="site-header__label">{{ $labels("contattaci") }}</div>
            <NavMenu class="site-header__menu" name="contact" />
          </div>
        </div>
        <LanguageSelector />
      </div>
    </div>
  </header>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { routerLinkWithLang } from "@/assets/js/utils";

import NavMenu from "@/components/template-parts/NavMenu";
import LanguageSelector from "@/components/template-parts/LanguageSelector";

export default {
  name: "SiteHeader",
  components: {
    NavMenu,
    LanguageSelector,
  },
  props: {},
  computed: {
    home() {
      return routerLinkWithLang("/", this.$store.state.lang);
    },
    perLaFil() {
      return routerLinkWithLang("/tu-per-lafil", this.$store.state.lang);
    },
  },
  data() {
    return {
      isVisible: false,
      site: this.$store.state.site,
      height: "0px",
    };
  },
  mounted() {
    this.onScroll();
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    toggleNav() {
      this.isVisible = !this.isVisible;
      if (this.isVisible) {
        const { height, top } = document
          .querySelector(".site-header__body")
          .getBoundingClientRect();
        if (window.innerHeight < height + top) {
          disableBodyScroll(this.$refs.wrapper);
          this.height = `${
            window.innerHeight -
            72 -
            document.querySelector(".site-header__header").offsetHeight
          }px`;
        } else {
          this.height = `${height}px`;
        }
      } else {
        this.height = "0px";
        enableBodyScroll(this.$refs.wrapper);
      }
    },
    onScroll() {
      this.isVisible = false;
      this.height = "0px";
      enableBodyScroll(this.$refs.wrapper);
    },
  },
  watch: {
    $route: function () {
      this.isVisible = false;
      this.height = "0px";
      enableBodyScroll(this.$refs.wrapper);
    },
  },
};
</script>

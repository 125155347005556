<template>
  <main class="main main--privacy-policy">
    <section class="section section--1">
      <div class="section__wrapper">
        <div v-if="content" class="section__rich-text" v-html="content.content"></div>
      </div>
    </section>
  </main>
</template>

<script>

import { fetchPrivacyPolicy } from '@/api'

export default {
  data() {
    return {
      content: null
    }
  },
  async created() {
    const { data } = await fetchPrivacyPolicy();
    this.content = data
    this.$store.dispatch('updateDocTitle', { parts: [ 'Privacy Policy', this.$store.state.site.description ] })
  }
}
</script>

<template>
  <router-link v-if="isVueLink" :to="$store.getters.internalLink({ url: link.url })" :class="internalClass">
    <slot>{{linkText}}</slot>
  </router-link>
  <a v-else :href="link.url" target="_blank" :class="internalClass">
     <slot>{{linkText}}</slot>
  </a>
</template>

<script>
export default {
  name: 'WpLink',
  props: {
    link: {
      required: true
    },
    internalClass: {
      required: false
    }
  },
  data() {
    return {}
  },
  computed: {
    isMail() {
      let isMail = false
      if (this.link.url && this.link.url.indexOf('mailto') == 0) {
        isMail = true
      }
      return isMail
    },
    isVueLink() {
      if (this.link.target || this.isMail) {
        return false
      }
      return true
    },
    linkText() {
      if (this.isMail) {
        return `↗ ${this.link.title}`
      } else {
        return `${this.link.title} ↗`
      }
    }
  },
  methods: {
  },
  mounted() {
  }
}

</script>

<template>
  <main class="main main--thankyoupage">
    <section class="section section--1" v-if="order" >
      <div class="section__wrapper section__wrapper--vertical-centered">
        <div>
          <div class="section__label">
            {{ $labels('grazie') }}
          </div>
          <div class="section__title">
            <span v-if="order.status == 'processing' || order.status == 'completed'">
              {{ $labels('transizionesuccesso') }}
            </span>
            <span v-if="order.status == 'on-hold' || order.status == 'pending'">
              {{ $labels('transizionepending') }}
            </span>
            <br>
            <span v-if="order.status == 'processing' || order.status == 'completed'">
              {{ $labels('oraseianchetu') }} "{{order.line_items[0].name}}" {{ $labels('dellafil') }}.
            </span>
          </div>
          <div class="section__rich-text">
            <h2 v-if="order.status == 'processing' || order.status == 'completed'">
              {{ $labels('pagamentodi') }} {{order.total}} {{order.currency_symbol}} {{ $labels('avvenutoconsuccesso') }}.
              <br>{{ $labels('perqualsiasinecessita') }} #{{order.number}}.
            </h2>
            <h2 v-if="order.status == 'on-hold' || order.status == 'pending'">
              {{ $labels('siamoinattesa') }} {{order.total}} {{order.currency_symbol}}.
              <br>{{ $labels('unavoltaricevuto') }} "{{order.line_items[0].name}}" {{ $labels('dellafil') }}.
              <br>{{ $labels('perqualsiasinecessita') }} #{{order.number}}.
            </h2>
          </div>
          <router-link :to="home" class="section__button section__button--black-negative">{{ $labels('tornallahome') }}</router-link>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { routerLinkWithLang } from '@/assets/js/utils'

import ResponsiveImage from '@/components/utility/ResponsiveImage'
import WpRichText from '@/components/utility/WpRichText'
import { fetchWooCommerceItems, emptyCart } from '@/api'

export default {
  name: 'ThankYouPage',
  components: { 
    ResponsiveImage,
    WpRichText
  },
  data() {
    return {
      order: null,
      request: {
        type: 'orders',
        params: {
          'id': this.$route.params.orderId,
          'order_key': this.$route.query.key,
        }
      },
    }
  },
  computed: {
    home() {
      return routerLinkWithLang('/', this.$store.state.lang)
    },
  },
  methods: {
    async getOrder() {
      const { data } = await fetchWooCommerceItems(this.request);
      // Attesa che arrivi update di info da stripe / paypal
      if (data.status == 'on-hold' || data.status == 'pending') {
        setTimeout(async() => {
          const { data } = await fetchWooCommerceItems(this.request);
          this.order = data  
        }, 4000)
      } else {
        this.order = data
      }
      emptyCart(this.$cookies)
    },
  },
  created () {
    if (this.$route.params.orderId != null && this.$route.query.key != null) {
      this.getOrder();
    } else {
      this.$router.push('/');
    }
    this.$store.dispatch('updateDocTitle', { parts: [ 'Grazie!', this.$store.state.site.description ] })
  }
}
</script>
import _ from "lodash";

import store from "@/store";

// // Route components
import NotFound from "@/pages/404";
import Single from "@/pages/Single";
import SingleConcerti from "@/pages/SingleConcerti";
import Page from "@/pages/Page";
import Checkout from "@/pages/Checkout";
import PrivacyPolicy from "@/pages/PrivacyPolicy";
import ThankYouPage from "@/pages/ThankYouPage";
// import ParentOverlaySelector from "./parentOverlaySelector.js";

// Questo è da rendere dinamico
const Singles = {
  SingleConcerti: SingleConcerti,
  SingleDiconoDiNoi: Single,
  SingleProgetti: Page,
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const { show_on_front, page_for_posts, page_on_front } =
  __VUE_WORDPRESS__.routing;
const { postTypes, i18n } = __VUE_WORDPRESS__.state;

let langs = null;

if (i18n) {
  store.commit("ADD_LANGUAGES", Object.keys(i18n.langs));

  langs = Object.keys(i18n.langs).filter((lang) => lang !== i18n.default);
  langs = langs.join("|");
} else {
  store.commit("ADD_LANGUAGES", ["default"]);
}

const routes = [
  {
    path: `/:lang(${langs})?/404`,
    components: {
      default: NotFound,
      overlay: null,
    },
    name: "404",
  },
  {
    path: `/:lang(${langs})?/checkout/order-received/:orderId`,
    components: {
      default: ThankYouPage,
      overlay: null,
    },
    name: "ThankYouPage",
  },
  {
    path: "/my-account/view-order/:orderId",
    components: {
      default: ThankYouPage,
      overlay: null,
    },
    name: "ThankYouPage",
  },
  {
    path: `/:lang(${langs})?/checkout`,
    components: {
      default: Checkout,
      overlay: null,
    },
    name: "Checkout",
  },
  {
    path: `/:lang(${langs})?/cart`,
    components: {
      default: Checkout,
      overlay: null,
    },
    name: "Checkout",
  },
  {
    path: `/:lang(${langs})?/privacy-policy`,
    components: {
      default: PrivacyPolicy,
      overlay: null,
    },
    name: "PrivacyPolicy",
  },
  {
    path: `/:lang(${langs})?/`,
    components: {
      default: Page,
      overlay: null,
    },
    meta: { isParentWithOverlay: true },
    name: "Home",
    props: {
      default: (route) => ({
        showOnFront: show_on_front,
        slug: page_on_front ? page_on_front : "",
      }),
    },
  },
];

Object.values(postTypes).forEach((postType) => {
  if (!postType._builtin) {
    const postTypeNameCapitalized = capitalizeFirstLetter(
      _.camelCase(postType.rewrite.slug)
    );
    const singleRoute = {
      path: `/:lang(${langs})?/${postType.rewrite.slug}/:slug`,
      components: {
        default: Singles[`Single${postTypeNameCapitalized}`]
          ? Singles[`Single${postTypeNameCapitalized}`]
          : Single,
        overlay: null,
      },
      name: "Single" + postTypeNameCapitalized,
      props: {
        default: (route) => ({
          postType: postType.name,
          slug: route.params.slug,
        }),
        overlay: null,
      },
    };
    // Overlay Setup
    if (postType.name === "concerti") {
      singleRoute.meta = { isOverlay: true };
      singleRoute.components = {
        default: Page,
        overlay: Singles[`Single${postTypeNameCapitalized}`]
          ? Singles[`Single${postTypeNameCapitalized}`]
          : Single,
      };
      singleRoute.props = {
        default: (route) => {
          return {
            showOnFront: show_on_front,
            slug: page_on_front ? page_on_front : "",
            postType: "pages",
          };
        },
        overlay: (route) => ({
          postType: postType.name,
          slug: route.params.slug,
        }),
      };
    }
    routes.push(singleRoute);
  }
});

routes.push(
  /**
   * This also functions as a catch all redirecting
   * to 404 if a page isn't found with slug prop
   */
  {
    path: `/:lang(${langs})?/:slug+`,
    components: {
      default: Page,
      overlay: null,
    },
    name: "Page",
    meta: { isParentWithOverlay: true },
    props: {
      default: (route) => {
        return {
          slug: route.params.slug
            .split("/")
            .filter((s) => s)
            .pop(),
        };
      },
    },
  }
);

export default routes.filter((route) => route); // Removes empty route objects

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.$route.name != 'ThankYouPage' &&
    _vm.$route.name != '404' &&
    _vm.$route.name != 'Checkout'
  )?_c('div',{key:_vm.lang,staticClass:"site-footer"},[_c('div',{staticClass:"section__wrapper--full"},[_c('div',{staticClass:"logo logo--complete-white"}),_c('div',{staticClass:"site-footer__header"},[_c('div',{staticClass:"section__label",domProps:{"innerHTML":_vm._s(_vm.$store.state.settings[_vm.lang].footer_description)}})]),_c('div',{staticClass:"site-footer__body section__columns section__columns--4"},[_vm._l((_vm.$store.state.settings[_vm.lang].footer_list),function(list,index){return _c('div',{key:'list-' + index,staticClass:"site-footer__block"},[_c('div',{staticClass:"section__label--small",domProps:{"innerHTML":_vm._s(list.label)}}),_c('WpRichText',{staticClass:"section__label",attrs:{"field":list.content}})],1)}),_c('div',{staticClass:"site-footer__block"},[_c('dt',{staticClass:"section__label--small"},[_vm._v(_vm._s(_vm.$labels("newsletter")))]),_c('div',{staticClass:"section__newsletter"},[_c('form',{attrs:{"action":"https://lafil.us19.list-manage.com/subscribe/post","method":"get","target":"_blank"}},[_c('input',{attrs:{"type":"hidden","name":"u","value":"cb3382536aa50a3ddde26521e"}}),_c('input',{attrs:{"type":"hidden","name":"id","value":"8cb6fa8fc1"}}),_c('input',{attrs:{"type":"text","name":"MERGE0","id":"","placeholder":_vm.$labels('latuamail')}}),_c('button',{attrs:{"type":"submit","value":"Invia"}},[_vm._v("↗")])])])]),_c('dl',{staticClass:"site-footer__block"},[_c('dt',{staticClass:"section__label--small"},[_vm._v(_vm._s(_vm.$labels("sitemap")))]),_c('NavMenu',{staticClass:"site-footer__menu",attrs:{"name":"main"}})],1),_c('div',_vm._l((_vm.$store.state.settings[_vm.lang].footer_credits),function(list,index){return _c('div',{key:'list-' + index,class:{
            'site-footer__block':
              index < _vm.$store.state.settings[_vm.lang].footer_credits.length - 1,
          }},[_c('div',{staticClass:"section__label--small",domProps:{"innerHTML":_vm._s(list.label)}}),_c('WpRichText',{staticClass:"section__label",attrs:{"field":list.content}})],1)}),0)],2)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div v-if="items.length > 0">
    <ArchiveFilter
      @filter="filter"
      :items="data.filter_bar && filterItems.length > 1 ? filterItems : []"
      :theme="themes.get(data.post_type)"
    />
    <div v-if="data.post_type === 'products'">
      <div
        :class="['section__links', `section__links--${filteredItems.length}`]"
      >
        <Thumb
          class="section__link"
          v-for="item in filteredItems"
          :key="item.id"
          :link="item"
        />
      </div>
      <div class="section__price-notes">
        <div
          class="section__price-note"
          v-for="(priceDetail, index) in settings[lang].price_details"
          :key="index"
        >
          <div><span v-for="number in index + 1" :key="number">*</span></div>
          <div v-html="priceDetail.price_detail"></div>
        </div>
      </div>
    </div>
    <div v-else>
      <PostItem v-for="item in filteredItems" :key="item.id" :post="item" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

import PostItem from "@/components/template-parts/PostItem";
import Thumb from "@/components/template-parts/Thumb";
import PageBuilder from "@/components/template-parts/PageBuilder";
import ArchiveFilter from "./filter";

export default {
  name: "Archive",
  components: {
    PostItem,
    PageBuilder,
    ArchiveFilter,
    Thumb,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    items: [],
    filterItems: [],
    activeFilter: null,
    themes: new Map([
      ["progetti", "blue"],
      ["dicono-di-noi", "purple"],
    ]),
  }),
  mounted() {
    this.loadItems();
  },
  computed: {
    ...mapGetters(["isMobile", "lang"]),
    ...mapState(["settings"]),
    filteredItems() {
      if (!this.activeFilter) {
        return this.items;
      }
      return this.items.filter((item) =>
        this.taxonomy(item).some((term) => term.term_id === this.activeFilter)
      );
    },
    taxonomyParam() {
      let v = false;
      if (this.data.taxonomy.length) {
        v = {
          [this.data.taxonomy[0].taxonomy]: this.data.taxonomy.map(
            (t) => t.term_id
          ),
        };
      }
      return v;
    },
  },
  methods: {
    ...mapActions(["getItems", "getWooCommerceItems"]),
    taxonomy(item) {
      return Object.values(item.pure_taxonomies)[0] || [];
    },
    loadItems() {
      const request = {
        type: this.data.post_type,
        params: {
          per_page: this.data.max || 100,
          lang: this.lang,
          date: this.data.period,
          ...(this.taxonomyParam || []),
        },
        showLoading: true,
      };

      const handleResponse = (response) => {
        this.items = response;
        const taxonomyArray = response.map((item) => this.taxonomy(item));
        taxonomyArray.forEach((group) => {
          if (!group) {
            return;
          }
          group.forEach((term) => {
            if (
              !this.filterItems.some((item) => item.term_id === term.term_id)
            ) {
              this.filterItems.push(term);
            }
          });
        });
      };

      if (this.data.post_type === "products") {
        this.getWooCommerceItems(request).then((response) =>
          handleResponse(response)
        );
      } else
        this.getItems(request).then((response) => handleResponse(response));
    },
    filter(id) {
      this.activeFilter = id;
    },
  },
};
</script>

<template>
  <nav>
    <div v-for="(item, index) in menu.items" :key="'menu-item-' + index">
      <router-link
        :class="item.css"
        :to="$store.getters.internalLink({ url: item.url })"
        v-if="!isExternalLink(item.url)"
        :target="item.target"
        :title="item.title"
        v-html="item.content"
      ></router-link>
      <a
        :class="item.css"
        v-else
        :href="item.url"
        :target="item.target"
        v-html="item.content"
      ></a>
      <div class="menu__children" v-if="item.children.length">
        <div v-for="child in item.children" :key="child.url">
          <router-link
            :to="$store.getters.internalLink({ url: child.url })"
            v-if="!isExternalLink(child.url)"
            :target="child.target"
            :title="child.title"
            v-html="child.content"
          ></router-link>
          <a
            v-else
            :href="child.url"
            :target="child.target"
            v-html="child.content"
          />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavMenu",
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    menu() {
      return this.$store.getters.menu({ name: this.name });
    },
  },
  methods: {
    isExternalLink(url) {
      return (
        url.indexOf("http") === 0 ||
        url.indexOf("mailto") === 0 ||
        url.indexOf("tel") === 0
      );
    },
  },
};
</script>

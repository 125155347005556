import Vue from 'vue'

// function disableScrolling(self) {
//   const lastScrollPosition = window.scrollY
//   self.$root.lastScrollPosition = lastScrollPosition;
//   document.querySelector('html').classList.add('scroll-disabled')
//   document.querySelector('body').style.top = `-${lastScrollPosition}px`;
// }
//
// function enableScrolling(self) {
//   const lastScrollPosition = self.$root.lastScrollPosition;
//   document.querySelector('html').classList.remove('scroll-disabled')
//   document.querySelector('body').style.removeProperty('top');
//   setTimeout(function () {
//     window.scrollTo(0, lastScrollPosition);
//   }, 1)
// }

async function inert(self, status = true) {
  await self.$nextTick();
  [...self.$root.$el.children].forEach((child) => {
    if (child === self.$el || child.contains(self.$el)) return;
    child.inert = status;
  });
}

function focusFirstDescendant(self) {
  self.$root.previousActiveElement = document.activeElement;
  if (self.$el) {
    const focusable = self.$el.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    if (focusable[0] && focusable[0].focus) focusable[0].focus();
  }
}

function restoreFocus(self) {
  const element = self.focusElement || self.$root.previousActiveElement;
  if (element && element.focus) element.focus();
}

function close (e) {
  const ESC = 27;
  if (e.keyCode === ESC && window.overlayCloseFn) {
    window.overlayCloseFn()
  }
}

Vue.directive('overlay', {
  bind: function (el, binding, vnode) { //el, binding, vnode
    // disableScrolling(vnode.context)
    document.querySelector('html').classList.add('scroll-disabled')
    // inert(vnode.context)
    focusFirstDescendant(vnode.context)
    window.overlayCloseFn = vnode.context.close
    document.addEventListener('keyup', close)
  },
  unbind: async function (el, binding, vnode) { //el, binding, vnode
    // enableScrolling(vnode.context)
    document.querySelector('html').classList.remove('scroll-disabled')
    // await inert(vnode.context, false);
    restoreFocus(vnode.context)
    document.removeEventListener('keyup', close)
  }
})

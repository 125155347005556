import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/directives/overlay'

import VueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(VueSmoothScroll)

import VueDragscroll from 'vue-dragscroll'
Vue.use(VueDragscroll)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueAnalytics from 'vue-analytics'
Vue.use(VueAnalytics, {
  id: 'UA-178416860-1',
  router
})

import labels from '@/assets/js/labels';
Vue.use(labels);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


<template>
  <div class="overlay scroller" v-overlay>
    <main
      class="main main--concerto"
      :class="'main--' + $route.params.slug"
      v-if="post"
    >
      <section class="section section--1" id="header">
        <div class="section__wrapper">
          <h4 class="section__label">
            <span v-html="dateTime" v-if="post.acf.date"></span>
            <span
              v-if="
                post.pure_taxonomies.progetti &&
                post.pure_taxonomies.progetti[0]
              "
              v-html="post.pure_taxonomies.progetti[0].name"
            ></span>
          </h4>
          <div class="section__title" v-html="post.title.rendered"></div>
          <div class="section__subtitle" v-html="post.acf.subtitle"></div>
        </div>
      </section>
      <section class="section--2" id="meta" v-if="post.acf.attributes">
        <div class="section__wrapper--full">
          <div
            class="section__meta section__grid"
            :class="'section__grid--' + (post.acf.attributes.length + 1)"
          >
            <div v-if="post.acf.city || post.acf.location">
              <p v-html="post.acf.city"></p>
              <p v-html="post.acf.location"></p>
            </div>
            <div
              v-for="(attribute, index) in post.acf.attributes"
              :key="'attribute ' + index"
            >
              <p v-html="attribute.label"></p>
              <p v-html="attribute.value"></p>
            </div>
          </div>
        </div>
      </section>
      <section class="section--3 section__wrapper--full" id="content">
        <WpRichText
          :field="post.content"
          class="section__rich-text"
        ></WpRichText>
      </section>
      <section class="section--4" id="gallery" v-if="post.acf.gallery">
        <div class="section__wrapper section__wrapper--no-margin">
          <Slider :setting="$root.gallerySetting" class="section__gallery">
            <ResponsiveImageVW
              v-for="(gallery, galleryIndex) in post.acf.gallery"
              :key="'gallery-' + galleryIndex"
              class="section__gallery-image"
              :wpImageObject="gallery"
            ></ResponsiveImageVW>
          </Slider>
        </div>
      </section>
      <section
        class="section--5"
        id="musicians"
        v-if="post.acf.musicians && post.acf.musicians[0]"
      >
        <div class="section__wrapper--full">
          <div class="section__accordions">
            <div class="section__accordions-header">
              <div>{{ $labels("strumento") }}</div>
              <div>{{ $labels("musicista") }}</div>
              <div><!--Accademia--></div>
            </div>
            <div class="section__accordions-body">
              <div
                class="accordion"
                v-for="(musician, indexMusician) in post.acf.musicians"
                :key="'musician-' + indexMusician"
                v-if="!$store.getters.isMobile"
              >
                <div
                  class="accordion__header"
                  v-html="musician.instrument"
                ></div>
                <div class="accordion__body">
                  <div
                    class="accordion__element"
                    v-for="(person, indexPerson) in musician.people"
                    :key="'person-' + indexPerson"
                  >
                    <div v-html="person.name"></div>
                    <div v-html="person.academy"></div>
                  </div>
                </div>
              </div>
              <accordion v-if="$store.getters.isMobile">
                <accordion-item
                  class="accordion"
                  v-for="(musician, indexMusician) in post.acf.musicians"
                  :key="'musician-' + indexMusician"
                >
                  <template slot="accordion-trigger">
                    <div class="accordion__header">
                      <div v-html="musician.instrument"></div>
                      <div class="accordion__icon">↓</div>
                    </div>
                  </template>
                  <template slot="accordion-content">
                    <div class="accordion__body">
                      <div
                        class="accordion__element"
                        v-for="(person, indexPerson) in musician.people"
                        :key="'person-' + indexPerson"
                      >
                        <div v-html="person.name"></div>
                        <div v-html="person.academy"></div>
                      </div>
                    </div>
                  </template>
                </accordion-item>
              </accordion>
            </div>
          </div>
        </div>
      </section>
      <section v-else class="section__wrapper"></section>
    </main>
  </div>
</template>

<script>
import gsap from "gsap";
import { mapGetters, mapState } from "vuex";

import Single from "@/pages/Single.vue";

import ResponsiveImageVW from "@/components/utility/ResponsiveImageVW";
import Slider from "@/components/utility/Slider";
import WpRichText from "@/components/utility/WpRichText";
import Accordion from "@/components/utility/Accordion";
import AccordionItem from "@/components/utility/AccordionItem";
import { fromRatio } from "../../../../plugins/woocommerce/packages/woocommerce-admin/dist/chunks/5.8439b417a7829e9e2ae2";

export default {
  name: "SingleConcerti",
  extends: Single,
  components: {
    ResponsiveImageVW,
    Slider,
    WpRichText,
    Accordion,
    AccordionItem,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["lang", "prevLang", "defaultLang", "langs"]),
    ...mapState(["from"]),
    dateTime() {
      if (this.post.acf.time) {
        return `${this.post.acf.date} – ${
          this.$labels ? this.$labels("ore") : "ore"
        } ${this.post.acf.time}`;
      }
      return this.post.acf.date;
    },
  },
  mounted() {
    if (this.$el && this.$el.closest) {
      const scroller = this.$el.closest(".scroller");
      scroller.addEventListener("scroll", this.handleScroll);
    }
  },
  destroyed() {
    if (this.$el && this.$el.closest) {
      const scroller = this.$el.closest(".scroller");
      scroller.removeEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    handleScroll(event) {
      const scroller = this.$el.closest(".scroller");
      if (scroller) {
        const remainingToBottom =
          this.$el.querySelector(".main").offsetHeight - scroller.scrollTop;
        const percent = remainingToBottom / window.innerHeight;

        // Da 1.2, cioè poco prima che si veda l'elemento sotto, inizio a mettere il blur per non appesantire rendering
        // if (remainingToBottom < window.innerHeight) {
        if (percent > 1.2) {
          if (document.querySelector(".gecko")) {
            // if (document.querySelector('.main').style.filter != `blur(25px)`) {
            //   document.querySelector('.main').style.filter = `blur(25px)`;
            // }
          } else {
            if (document.querySelector(".gecko")) {
            } else {
              if (document.querySelector(".main").style.filter != `none`) {
                document.querySelector(".main").style.filter = `none`;
              }
            }
          }
        } else if (percent > 1) {
          if (document.querySelector(".gecko")) {
          } else {
            if (document.querySelector(".main").style.filter != `blur(25px)`) {
              document.querySelector(".main").style.filter = `blur(25px)`;
            }
          }
        } else {
          if (document.querySelector(".gecko")) {
            // Versione leggera
            // document.querySelector('.main').style.filter = `blur(25px)`;
          } else {
            document.querySelector(".main").style.filter = `blur(${parseInt(
              percent * 25
            )}px)`;
          }
        }

        if (remainingToBottom < 1) {
          // window.innerHeight*0.1
          this.close(false);
        }
      }
    },
    close(press = true) {
      document.querySelector(".main").style.filter = `none`;

      const params = {};

      if (this.lang != this.defaultLang) {
        params.lang = this.lang;
      }

      if (press) {
        gsap
          .to(this.$el, {
            duration: 0.5,
            ease: "expo.in",
            yPercent: -100,
          })
          .then(() => {
            this.$router.push({
              path: this.from.fullPath,
              params,
              query: {
                progetto: this.$route.query.progetto,
              },
            });
          });
      } else {
        this.$router.push({
          path: this.from.fullPath,
          params,
          query: {
            progetto: this.$route.query.progetto,
          },
        });
      }
    },
  },
};
</script>

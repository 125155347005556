import Vue from 'vue';
import store from '../../store/index'


const dictionary = {
  it: {
    contattaci: 'Contattaci',
    seguici: 'Seguici',
    scopridipiu: 'Scopri di più',
    diventa: 'Diventa',
    newsletter: 'Newsletter',
    sitemap: 'Sitemap',
    latuamail: 'La tua e-mail...',
    menu: 'Menu',
    chiudi: 'Chiudi',
    paginanontrovata: 'Pagina non trovata',
    strumento: 'Strumento',
    musicista: 'Musicista',
    notaprezzo1: 'Tutti i benefici legati ai concerti live saranno possibili solo alla ripresa della programmazione degli stessi. La lista con le date e i luoghi dei concerti sarà pubblicata una volta definito il programma.',
    notaprezzo2: 'Non include viaggio, alloggio, pasti.',
    notaprezzo3: 'Gli incontri potranno avvenire esclusivamente durante il periodo di prove e concerti programmati. Il luogo sarà scelto in base a dove si trova l’orchestra.',
    tornallahome: 'Torna alla home',
    ituoidati: 'I tuoi dati',
    nome: 'Nome',
    cognome: 'Cognome',
    indirizzoemail: 'Indirizzo e-mail',
    telefono: 'Telefono',
    citta: 'Città',
    indirizzo: 'Indirizzo',
    provincia: 'Provincia',
    cap: 'C.A.P.',
    campiobbligatori: 'Tutti i campi sono obbligatori',
    iltuoordine: 'Il tuo ordine',
    sceglimetodopagamento: 'Scegli il metodo di pagamento',
    intestatariobonifico: 'Intestatario bonifico',
    causalebonifico: 'Causale bonifico',
    versamentoper: 'Versamento per',
    per: 'per',
    ridirezionatopaypal: 'Verrai ridirezionato sul sito di PayPal. Al termine della procedura di pagamento tornerai sul nostro sito.',
    effettuaordine: `Effettua l'ordine`,
    operazioneincorso: 'Operazione in corso...',
    grazie: 'Grazie',
    transizionesuccesso: 'Transazione conclusa con successo',
    transizionepending: 'La tua richiesta è stata ricevuta con successo',
    oraseianchetu: 'Ora sei anche tu un',
    dellafil: 'della Fil',
    pagamentodi: 'Il pagamento di',
    avvenutoconsuccesso: 'è avvenuto con successo',
    perqualsiasinecessita: 'Per qualsiasi necessità ricorda che il tuo numero di riferimento è',
    siamoinattesa: 'Siamo in attesa del pagamento di',
    unavoltaricevuto: 'Una volta ricevuto ti faremo',
    'Bonifico bancario': 'Bonifico bancario',
    'PayPal': 'PayPal',
    'Carta di credito/debito': 'Carta di credito/debito',
    tu_per_la_fil: 'Tu per LaFil',
    ore: 'ore',
    perinformazioni: 'Per informazioni',
    perrichiestestampa: 'Per richieste stampa',
    scaricalarticolo: 'Scarica l\'articolo',
    continuaaleggere: 'Continua a leggere',
  },
  en: {
    contattaci: 'Contact us',
    seguici: 'Follow us',
    scopridipiu: 'Discover more',
    diventa: 'Become',
    newsletter: 'Newsletter',
    sitemap: 'Sitemap',
    latuamail: 'Your e-mail...',
    menu: 'Menu',
    chiudi: 'Close',
    paginanontrovata: 'Page not found',
    strumento: 'Instrument',
    musicista: 'Musician',
    notaprezzo1: 'All the benefits related to live concerts will only be possible once programming is resumed. The list with concert dates and  venues will be published once the program has been defined.',
    notaprezzo2: 'Does not include travel, accommodation, meals.',
    notaprezzo3: 'The meetings can only take place during the rehearsals and concert period. The venue will be chosen based on location of the orchestra.',
    tornallahome: 'Return to home page',
    ituoidati: 'Your data',
    nome: 'Name',
    cognome: 'Surname',
    indirizzoemail: 'E-mail address',
    telefono: 'Phone number',
    citta: 'City',
    indirizzo: 'Address',
    provincia: 'State',
    cap: 'ZIP Code',
    campiobbligatori: 'All fields are required',
    iltuoordine: 'Your order',
    sceglimetodopagamento: 'Choose a payment method',
    intestatariobonifico: 'Bank transfer holder',
    causalebonifico: 'Bank transfer subject',
    versamentoper: 'Donation recipient',
    per: 'for',
    ridirezionatopaypal: 'You will be redirected to the PayPal site. At the end of the payment procedure you will return to our site.',
    effettuaordine: 'Place the order',
    operazioneincorso: 'Operation in progress...',
    grazie: 'Thank you',
    transizionesuccesso: 'Transaction completed successfully',
    transizionepending: 'Your request has been successfully received',
    oraseianchetu: 'Now you are also a',
    dellafil: 'of La Fil',
    pagamentodi: 'The payment of',
    avvenutoconsuccesso: 'has been successful',
    perqualsiasinecessita: 'Should you require it, this is your reference number',
    siamoinattesa: 'We are awaiting the payment of',
    unavoltaricevuto: `Once received we you'll be`,
    'Bonifico bancario': 'Bank Transfer',
    'PayPal': 'PayPal',
    'Carta di credito/debito': 'Credit/debit card',
    tu_per_la_fil: 'You for LaFil',
    ore: 'H',
    perinformazioni: 'For further information',
    perrichiestestampa: 'Press enquiries',
    scaricalarticolo: 'Download article',
    continuaaleggere: 'Read more',
  }
}

const labels = {}

labels.install = () => {
  Vue.prototype.$labels = (label) => {
    return dictionary[store.state.lang ? store.state.lang : 'it'][label]
  }
};

export default labels;


function categorySlugFromParams({ cat1, cat2, cat3 }) {
  if (cat3 && (cat3 !== 'page' && cat2 !== 'page')) {
    return cat3
  } else if(cat2 && cat2 !== 'page') {
    return cat2
  } else {
    return cat1
  }
}

function pageFromPath(path) {
  let p = path.split('/').filter(i => i)
  if (p.length > 1 && p[p.length - 2] === 'page') {
    return parseInt(p[p.length - 1])
  } else {
    return 1
  }
}

function typeSlugAnchorFromPath(path) {
  const base = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '/'
  const cleanPath = path.replace(base, '')
  const p = cleanPath.split('/').filter(i => i)
  let preLast, last, anchor = null
  if (p[p.length-1].indexOf('#') == 0) {
    anchor = p[p.length-1]
    last = p[p.length-2]
    preLast = p[p.length-3]
  } else {
    last = p[p.length-1]
    preLast = p[p.length-2]
  }
  if (preLast) {
    return {
      type: preLast,
      slug: last,
      anchor: anchor
    }
  } else {
    return {
      type: 'pages',
      slug: last,
      anchor: anchor
    }
  }
}

export {
  categorySlugFromParams,
  pageFromPath,
  typeSlugAnchorFromPath
}

<template>
  <main class="main main--checkout">
    <section class="section section--1">
     <form class="section__wrapper section__cart" id="payment-form" @submit.prevent="onCheckoutSubmit()" novalidate>
       <div>
         <h2 class="section__title--x-small">{{ $labels('ituoidati') }}</h2>
         <div class="section__form">
          <input type="text" name="nome" id="nome" :placeholder="$labels('nome')" v-model.trim="$v.userDetailModel.nome.$model" required :class="{ 'error': $v.userDetailModel.nome.$error }">
          <input type="text" name="cognome" id="cognome" :placeholder="$labels('cognome')" v-model.trim="$v.userDetailModel.cognome.$model" required :class="{ 'error': $v.userDetailModel.cognome.$error }">
          <input type="text" name="email" id="email" :placeholder="$labels('indirizzoemail')" v-model.trim="$v.userDetailModel.email.$model" required :class="{ 'error': $v.userDetailModel.email.$error }">
          <input type="text" name="telefono" id="telefono" :placeholder="$labels('telefono')" v-model.trim="$v.userDetailModel.telefono.$model" :class="{ 'error': $v.userDetailModel.telefono.$error }">
          <select name="paese" id="paese" v-model.trim="$v.userDetailModel.paese.$model" :class="{ 'error': $v.userDetailModel.paese.$error }">
            <option :value="key" v-for="(country, key) in countries" :key="'country-'+key" v-html="country"></option>
          </select>
          <input type="text" name="citta" id="citta" :placeholder="$labels('citta')" v-model.trim="$v.userDetailModel.citta.$model" required :class="{ 'error': $v.userDetailModel.citta.$error }">
          <input type="text" name="indirizzo" id="indirizzo" :placeholder="$labels('indirizzo')" v-model.trim="$v.userDetailModel.indirizzo.$model" required :class="{ 'error': $v.userDetailModel.indirizzo.$error }">

          <input v-if="!statesFiltered" type="text" name="provincia" id="provincia" :placeholder="$labels('provincia')" v-model.trim="$v.userDetailModel.provincia.$model" required :class="{ 'error': $v.userDetailModel.provincia.$error }">
          <select v-else name="provincia" id="provincia" :placeholder="$labels('provincia')" v-model.trim="$v.userDetailModel.provincia.$model" :class="{ 'error': $v.userDetailModel.provincia.$error }">
            <option :value="key" v-for="(state, key) in statesFiltered" :key="'state-'+key" v-html="state"></option>
          </select>

          <input type="text" name="cap" id="cap" :placeholder="$labels('cap')" v-model.trim="$v.userDetailModel.cap.$model" required :class="{ 'error': $v.userDetailModel.cap.$error }">
         </div>
         <p v-if="submitStatus == 'error'" class="error">{{ $labels('campiobbligatori') }}</p>
       </div>
       <div>
         <h2 class="section__title--x-small">{{ $labels('iltuoordine') }}</h2>
         <div class="section__order">
           <div>
             <div class="section__order-details">
               <div v-html="product.name"></div>
               <div v-html="product.price_html"></div>
             </div>
             <hr>
             <div>
               <p>{{ $labels('sceglimetodopagamento') }}</p>
               <div>
                 <accordion ref="accordion">
                  <accordion-item class="accordion" v-for="(paymentGateway, index) in paymentGateways" :key="'musician-'+index">
                    <template slot="accordion-trigger">
                      <div class="accordion__header">
                        <div class="selector">
                          <div></div>
                        </div>
                        <div v-html="$labels(paymentGateway.title)"></div>
                      </div>
                    </template>
                    <template slot="accordion-content">
                      <div class="accordion__body">
                        <div v-if="paymentGateway.id == 'bacs'">
                          <p>{{ $labels('intestatariobonifico') }}</p>
                          <p v-html="$root.addBreakLines(paymentGateway.description)"></p>
                          <p>{{ $labels('causalebonifico') }}</p>
                          <p>{{ $labels('versamentoper') }} {{product.name}} {{ $labels('per') }} {{userDetailModel.cognome}} {{userDetailModel.nome}}</p>
                        </div>
                        <div v-if="paymentGateway.id == 'paypal'">
                          <p>{{ $labels('ridirezionatopaypal') }}</p>
                        </div>
                        <div v-if="paymentGateway.id == 'stripe'">
                          <div id="card-element"><!-- Elements will create input elements here --></div>
                          <p id="card-errors" class="error" role="alert"><!-- Error messages will be shown in this element --></p>
                        </div>
                      </div>
                    </template>
                  </accordion-item>
                </accordion>
               </div>
             </div>
             <div v-if="paymentRequestFailure" class="error">
              {{paymentRequestFailure.message}}
              </div>
             <div v-if="paymentRequestSuccess">
                {{paymentRequestSuccess.message}}
              </div>
           </div>
           <button class="section__button section__button--black-negative" id="place_order" :disabled="isDisabled()">
             <span v-if="paymentStatus === null">{{ $labels('effettuaordine') }}</span>
             <span v-else>{{ $labels('operazioneincorso') }}</span>
           </button>
         </div>
       </div>
     </form>
   </section>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';

import ResponsiveImage from '@/components/utility/ResponsiveImage'
import WpRichText from '@/components/utility/WpRichText'
import { fetchSingle } from '@/api'
import axios from 'axios'
import {loadStripe} from '@stripe/stripe-js';
import { createOrder, createPaymentIntent, getCartContent, fetchWooCommerceItems } from '@/api'
import Accordion from '@/components/utility/Accordion'
import AccordionItem from "@/components/utility/AccordionItem";
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CheckoutPage',
  components: {
    ResponsiveImage,
    WpRichText,
    Accordion,
    AccordionItem
  },
  data() {
    return {
      intentClientSecret: null,
      paymentMethod: null,
      paymentRequestFailure: null,
      paymentRequestSuccess: null,
      paymentStatus: null,
      order: null,
      countries: [],
      states: [],
      cart: null,
      paymentGateways: null,
      requestPaymentGateways: {
        type: 'payment_gateways',
      },
      requestStates: {
        type: 'countries',
      },
      submitStatus: null,
      stripe: null,
      stripeInsertingDataError: null,
      userDetailModel: {
        nome: '',
        cognome: '',
        email: '',
        telefono: '',
        paese: 'IT',
        citta: '',
        indirizzo: '',
        provincia: '',
        cap: '',
      },
    }
  },
  validations: {
    userDetailModel: {
      nome: {
        required,
      },
      cognome: {
        required,
      },
      email: {
        required,
      },
      telefono: {
        required,
      },
      paese: {
        required,
      },
      citta: {
        required,
      },
      indirizzo: {
        required,
      },
      provincia: {
        required,
      },
      cap: {
        required,
      },
    }
  },
  computed: {
    ...mapGetters(['lang', 'defaultLang', 'langs', 'currentPost']),
    paymentIntentId: function() {
      return this.intentClientSecret ?
        `${this.intentClientSecret.split('_')[0]}_${this.intentClientSecret.split('_')[1]}` :
        null;
    },
    product() {
      return this.$cookies.get('lafil-cart-product');
    },
    statesFiltered() {
      if (this.states && this.states[this.userDetailModel.paese]) {
        return this.states[this.userDetailModel.paese]
      }
    },
    // stripeSecret: function() {
    //   return this.intentClientSecret ?
    //     `${this.intentClientSecret.split('_')[2]}_${this.intentClientSecret.split('_')[3]}` :
    //     null;
    // }
  },
  methods: {
    async createOrder() {
      if (this.$refs.accordion && this.$refs.accordion.Accordion && this.$refs.accordion.Accordion.active !== null) {
        const paymentMethodIndex  = this.$refs.accordion.Accordion.active
        this.paymentMethod = this.paymentGateways[paymentMethodIndex]
        const payload = {
          billing: JSON.parse(JSON.stringify(this.userDetailModel)),
          paymentMethod: this.paymentMethod.id,
          intentId: this.paymentIntentId
        };
        const orderCreationResponse = await createOrder(payload);
        return orderCreationResponse.data;
      } else {
        this.submitStatus = 'error'
      }
    },

    async onCheckoutSubmit(stripe) {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'error'
        return
      } else {
        this.submitStatus = 'ok'
      }
      this.paymentStatus = 'pending'
      if (this.order == null) {
        this.order = await this.createOrder();
      }
      if (this.order.payment_method == "bacs") {
        this.gotoThankYouPage();
      } else if (this.order.payment_method == "paypal") {
        if (this.order.paypalIntent && this.order.paypalIntent.redirect) {
          const redirectUrl = this.order.paypalIntent.redirect;
          window.location = redirectUrl
        } else {
          this.paymentRequestFailure = {
            message: `Errore nella creazione dell'ordine.`
          }
        }
      } else if (this.order.payment_method == "stripe") {
        const confirmCardPaymentPayload = {
          payment_method: {
            card: this.card,
            billing_details: {
              name: `${this.order.billing.first_name} ${this.order.billing.last_name}`,
              "address": {
                "city": this.order.billing.city,
                "country": this.order.billing.country,      // qua va controllato l'input, perché Stripe si incazza se non riceve un id di una nazione su due lettere (es: IT, US). In prima fase è meglio lasciarlo vuoto
                "line1": this.order.billing.address_1,
                "line2": this.order.billing.address_2,
                "postal_code": this.order.billing.postcode,
                "state": this.order.billing.state,
              },
              "email": this.order.billing.email,
              "name": `${this.order.billing.first_name} ${this.order.billing.last_name}`,
              "phone": this.order.billing.phone,
            },
          },
          receipt_email: this.order.billing.email,
          // return_url: `/checkout/order-received/${order.id}&order_key=${order.order_key}`          //If you are handling next actions yourself, pass in a return_url. If the subsequent action is redirect_to_url, this URL will be used on the return path for the redirect.
        };

        this.paymentRequestFailure = null;
        this.stripe.confirmCardPayment(this.intentClientSecret, confirmCardPaymentPayload).then((result) => {
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)                             // TODO TO DO !
            console.warn(result.error);
            this.paymentRequestFailure = result.error;
            this.paymentStatus = null
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              console.warn("Order completed!");
              setTimeout(() => {
                this.gotoThankYouPage();
              }, 5000)
            }
          }
        });
      }
    },

    async mountStripeCard() {
      this.stripe = await loadStripe('pk_live_51H5C3HE8aPOX2UXMU3cOhzRjVPySTWP8913ziPaVTf2i8qeS5Ggo5qM6KcP2hdrtj56PODXjmoearhuCXEZuuy8s00qbeBzEXd', {
        locale: 'it'
      });
      var elements = this.stripe.elements();

      this.card = elements.create("card", {
        hidePostalCode: true,
        style: {
          base: {
          }
        }
      });
      this.card.mount("#card-element");

      this.card.on('change', ({error}) => {
        const displayError = document.getElementById('card-errors');
        if (error) {
          this.stripeInsertingDataError = true
          displayError.textContent = error.message;
        } else {
          displayError.textContent = '';
          this.stripeInsertingDataError = null
        }
      });
    },

    gotoThankYouPage() {
      if (this.order) {
        this.$router.push({
          name: 'ThankYouPage',
          params: {
            lang: this.lang,
            orderId: this.order.id,
          },
          query: {
            key: this.order.order_key
          }
        })
      }
    },

    isDisabled() {
      if (this.$refs.accordion && this.$refs.accordion.Accordion && this.$refs.accordion.Accordion.active !== null) {
        if (this.stripeInsertingDataError === null && this.paymentStatus === null) {
          return null
        }
      }
      return 'disabled';
    },

  },
  async created() {
    this.cart = await getCartContent(this.$cookies)
    var { data } = await fetchWooCommerceItems(this.requestStates);
    this.countries = data.countries
    this.states = data.states
    var { data } = await fetchWooCommerceItems(this.requestPaymentGateways);
    this.paymentGateways = data

    // HARD DISABLE STRIPE
    // const stripeIndex = this.paymentGateways.findIndex(element => {
    //   return element.id == 'stripe'
    // })
    // if (stripeIndex > -1) {
    //   this.paymentGateways.splice(stripeIndex, 1)
    // }
    
    if (!this.cart || !this.product) {
      this.$router.push('/');
    }
    const response = await createPaymentIntent(null);
    this.intentClientSecret = response.data.client_secret;
    if (!this.intentClientSecret) {
      location.reload();
    }
  },
  async mounted() {
    // var form = document.getElementById('place_order');

    // form.addEventListener('submit', async (ev) => {
    //   ev.preventDefault();
    //   await this.onCheckoutSubmit(stripe, card);
    // });
  },
  watch: {
    'paymentGateways': function() {
      const stripe = this.paymentGateways.find(paymentGateway => {
        return paymentGateway.id == 'stripe'
      })
      if (stripe) {
        this.mountStripeCard()
      }
    },
  }
}
</script>

<template lang="html">
  <div>
    <div :class="[sliderClasses ? sliderClasses : null, 'slider-container']">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import flickity from  'flickity'
import imagesLoaded from 'imagesloaded';
// import tnsCss from  '../../../../../../node_modules/flickity/css/flickity.css'

export default {
  data() {
    return {
      state: 'Slider',
      sliderSettings: null,
    }
  },
  components: {
  },
  props: ['setting', 'sliderClasses'],
  created() {
    // this.sliderSettings = JSON.parse(JSON.stringify(this.setting));
  },
  mounted() {
    this.$nextTick(() => {
      imagesLoaded(this.$el, (instance) => {
        var flickty = new flickity( this.getSliderContainerEl(),
          Object.assign(this.setting, {
            on: {
              ready: () => {
                this.getSliderContainerEl().classList.add('flickity-mounted')
              }
            }
          })
        );
      })
    });
  },
  methods: {
    getSliderContainerEl() {
      return this.$el.querySelector('.slider-container');
    },
    setLinks() {
      const links = this.$el.querySelectorAll('a');
      links.forEach((item, i) => {
        item.addEventListener('click', function (event) {
          event.preventDefault();
          if (item.dataset.href) {
            this.$router.push(item.dataset.href);
          }
        });
      });
    },
  }
}
</script>

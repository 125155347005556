<template>
  <div v-if="image && image.filename">
    <figure :id="'cover-'+id" v-if="type == 'image'" class="responsive-figure" :class="imageClass">
      <img class="responsive-image">
      <figcaption v-if="image.caption">{{image.caption}}</figcaption>
    </figure>

    <div class="responsive-image-background" :id="'cover-'+id" :class="imageClass" v-if="type == 'background'">
    </div>

  </div>
</template>

<script>
import Vue from 'vue'

const Api = require('@/api');

export default {
  name: 'ResponsiveImage',
  components: {
  },
  props: {
    'src': {},
    'type': {
      default: 'image'
    },
    'direction': {
      default: 'horizontal'
    },
    'imageClass': {},
  },
  computed: {

  },
  data () {
    return {
      name: 'ResponsiveImage',
      image: null,
      method: 'w',
      id: 0,
      with: null,
      imagesSizes: ["xxs", "xs", "s", "m", "l", "xl", "xxl"],
      imagesSizesVertical: ["xxs", "xs", "s", "m", "l", "xl", "xxl"],
      lastLoadedImage: null,
      isSettingImage: false,
    }
  },
  async created() {
    if (this.src && this.src.id) {
      this.id = this.src.id
      if (!this.src.filename) {
        // Chiamala
        // try {
        //   const retrievedFile = await Api.getFile(this.id);
        //   this.image = retrievedFile.data;
        // } catch (e) {
        //   console.log(e);
        // }
      } else {
        this.image = this.src
      }
    }

    this.with = `cover-${this.id}`

    if (this.direction == 'vertical') {
      this.method = 'h'
    }
  },
  mounted () {
    // const scroller = document.querySelector('.app');
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
    // After transition
    setTimeout(() => {
      this.setResponsiveImage()
    }, 200)
  },
  watch: {
    'image': function() {
      this.setResponsiveImage()
    },
    'src': async function(newV, oldV) {
      if (this.src && this.src.id) {
        if (this.$el && this.$el.querySelector('.responsive_placeholder--loaded')) {
          this.$el.querySelector('.responsive_placeholder--loaded').classList.remove('responsive_placeholder--loaded')
        }
        this.lastLoadedImage = null;
        this.id = this.src.id
        this.with = `cover-${this.id}`;
        if (!this.src.filename) {
          this.image = await Api.getFile(this.id);
        } else {
          this.image = this.src
        }
      }
    },
  },
  methods: {
    setImageValue() {
      if (this.src && this.src.id) {
        this.id = this.src.id
        if (!this.src.filename) {
          Api.getFile(this, 'image', this.id)
        } else {
          this.image = this.src
        }
      }
    },
    handleScroll() {
      this.setResponsiveImage()
    },

    handleResize() {
      this.setResponsiveImage()
    },

    setResponsiveImage() {
      if (!this.image || !window.navigator.onLine || this.isSettingImage) {
        return
      }

      setTimeout(() => {
        let el = this.$el;
        if (this.type == 'image' && this.$el && this.$el.querySelector) {
          el = this.$el.querySelector('img');
        }
        if (this.type == 'background' && this.$el && this.$el.querySelector) {
          el = this.$el.querySelector('.responsive-image-background');
        }

        try {
          this.isSettingImage = true;
          const vnode = null;

          if (!el || el.classList == null) {
            return;
          }

          let container = document.querySelector('#' + this.with)

          // Check if already changed
          let imageSizes = this.image.sizes

          if (this.type == 'image' && container && !el.classList.contains('responsive-image--loaded')) {
            // let imageWidth = imageSizes[`${dimensions}-width`];
            // let imageHeight = imageSizes[`${dimensions}-height`];
            let imageWidth = container.offsetWidth;
            let imageHeight = container.offsetHeight;
            el.dataset.heightOriginal = imageHeight
            el.dataset.widthOriginal = imageWidth

            // Set Placeholder
            var isTherePlaceholder = false
            if (el.previousElementSibling) {
              if (el.previousElementSibling.classList) {
                isTherePlaceholder = true
              }
            }
            if (!isTherePlaceholder) {
              var placeholder = document.createElement('div');
              placeholder.className = "responsive_placeholder";
              this.insertBeforeCustom(placeholder, el);
              let imageRatio = imageHeight/imageWidth*100;
              if (this.image.width && this.image.height) {
                imageRatio = this.image.height/this.image.width*100;
              }
              placeholder.style.paddingBottom = imageRatio+"%";
            }
          }

          if (!this.isInViewport(container || el)) {
            return;
          }

          let previousSize = null
          let getSrc = true
          let isImageValuePresent = true
          let containerRectangle
          if (container) {
            previousSize = container.dataset.currentSize
            let containerWidth = container.offsetWidth;
            let containerHeight = container.offsetHeight;
            containerRectangle = Array(containerWidth, containerHeight);
            if (this.isRetina()) {
              containerRectangle = Array(containerWidth*2, containerHeight*2);
            }
          }

          if (previousSize && containerRectangle) {
            let previousImageRectangle = Array(imageSizes[`${previousSize}-width`], imageSizes[`${previousSize}-height`]);
            if (this.isRectangleSmallerThanRectangle(containerRectangle, previousImageRectangle)) {
              if (this.type == "image" && !el.getAttribute('src')) {
                isImageValuePresent = false
              }
              if (this.type == "background" && !el.style.backgroundImage) {
                isImageValuePresent = false
              }
              if (isImageValuePresent) {
                getSrc = false
              }
            }
          }

          if (getSrc || this.lastLoadedImage == null) {
            let correctSize = this.getResponsiveImage(el, vnode)
            let imageResponsive = imageSizes[correctSize]
            let finalSrc
            if (imageResponsive) {
              finalSrc = imageResponsive
            } else {
              finalSrc = this.image.url
            }
            this.lastLoadedImage = finalSrc;
            if (this.type == "image") {
              const onImageLoad = function() {
                var placeholderHTML = el.previousElementSibling
                placeholderHTML.classList.add("responsive_placeholder--loaded");
                el.classList.add("responsive-image--loaded");
              }
              el.setAttribute('src', finalSrc)
              // For TNS reproduce on dupicated element
              // const duplicated = document.querySelectorAll('#'+this.with)
              // duplicated.forEach((item, i) => {
              //   item.setAttribute('src', finalSrc)
              //   item.onload = onImageLoad
              // });
              el.onload = onImageLoad
            }
            if (this.type == "background") {
              el.style.backgroundImage = `url(${finalSrc})`
              // For TNS reproduce on dupicated element
              // const duplicated = document.querySelectorAll('#'+this.with)
              // duplicated.forEach((item, i) => {
              //   item.style.backgroundImage = `url(${finalSrc})`
              // });
            }
          }
        } catch (e) {
          console.error(e);
          console.error(el);
        } finally {
          this.isSettingImage = false;
        }
      }, 100);
    },

    isRetina() {
      return false;
      // var isRetina = ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)').matches)) || (window.devicePixelRatio && window.devicePixelRatio >= 2)) && /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
      // if (!isRetina) {
      //   isRetina = (window.devicePixelRatio >= 2);
      // }
      // return isRetina
    },

    isInViewport(elem) {
      if (elem) {
        try {
          var bounding = elem.getBoundingClientRect();
          return (bounding.top - window.innerHeight*2.5) < 0
        } catch (e) {
          // console.log('elem has no getBoundingClientRect fn!', elem)
        }
      }
    },

    insertAfter(newNode, referenceNode) {
      referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    },

    insertBeforeCustom(newNode, referenceNode) {
      referenceNode.parentNode.insertBefore(newNode, referenceNode);
    },

    isRectangleSmallerThanRectangle(r1, r2) {
      // Image case with height = 0;
      if (r1[1] != 0) {
        if (r1[0] < r2[0] && r1[1] < r2[1]) {
          return true;
        } else {
          return false;
        }
      } else {
        if (r1[0] < r2[0]) {
          return true;
        } else {
          return false;
        }
      }
    },

    getResponsiveImage(el, vnode) {
      let correctSize, ratio, containerWidth, containerHeight, imageRectangle, containerRectangle;
      let imageWidth = 400;
      let imageHeight = 300;
      let imageSizes = this.image.sizes

      let container = document.querySelector('#'+this.with)

      // Go on
      if (container) {
        containerWidth = container.offsetWidth;
        containerHeight = container.offsetHeight;
      } else {
        containerWidth = window.innerWidth;
        containerHeight = window.innerHeight
      }

      containerRectangle = Array(containerWidth, containerHeight);
      if (this.isRetina()) {
        containerRectangle = Array(containerWidth*2, containerHeight*2);
      }

      let imageSizesArr = JSON.parse(JSON.stringify(this.imagesSizes));
      if (this.method == 'h') {
        imageSizesArr = this.imagesSizesVertical
      }

      for (var i = 0; i < imageSizesArr.length; i++) {
        var currentSize = imageSizesArr[i].toLowerCase();
        if (imageSizes[`${currentSize}-width`] && imageSizes[`${currentSize}-width`]) {
          var responsiveImageRectangle = Array(imageSizes[`${currentSize}-width`], imageSizes[`${currentSize}-width`]);
          if (this.isRectangleSmallerThanRectangle(containerRectangle, responsiveImageRectangle) && correctSize == null) {
            correctSize = currentSize;
            if (container) {
              container.dataset.currentSize = correctSize
            }
          }
        }
      }
      if (!correctSize) {
        correctSize = 'original';
      }
      return correctSize;
    }
  }
}
</script>

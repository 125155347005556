<template>
  <div
    v-html="rendered || field"
  ></div>
</template>

<script>
export default {
  name: 'WpRichText',
  props: {
    field: {
      required: true
    },
  },
  data() {
    return {}
  },
  computed: {
    rendered() {
      if (this.field && this.field.rendered != "") {
        return this.field.rendered
      }
      if (this.field && this.field.rendered == "") {
        return " "
      }
    },
  },
  methods: {
  },
  mounted() {
    if (!this.$el) {
      return
    }
    this.$el.querySelectorAll('a[data-internal-link="true"]').forEach(link => {
      if (!link.dataset.parsed) {
        link.dataset.parsed = true
        link.href = link.dataset.href;
        const clickHandler = event => {
          event.preventDefault()
          this.$router.push(this.$store.getters.internalLink({ url: link.dataset.href }));
        }
        var clickType = ('ontouchstart' in document.documentElement ? "touchstart" : "click");
        link.addEventListener(clickType, clickHandler)
      }
    })
  }
}

</script>

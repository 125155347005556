<template>
  <div class="header__language">
    <ul>
      <li
        v-for="item in langs"
        :data-selected="item.code === lang"
        :key="item.code"
      >
        <a 
          :data-value="item.code"
          @click="changeLanguage"
        >
          {{ item.code }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LanguageSelector',
  data() {
    return {
      value: '',
    };
  },
  computed: {
    ...mapGetters(['lang', 'defaultLang', 'langs', 'currentPost']),
  },
  created() {
    this.value = this.lang;
  },
  methods: {
    checkLanguage(val) {
      return this.currentPost.wpml_translations.find(
        (translation) => translation.locale === val,
      );
    },
    changeLanguage(e) {
      if (e.target.dataset.value === this.value) return;
      this.value = e.target.dataset.value;

      let path = this.value === this.defaultLang ? '/' : `/${this.value}`;
      if (
        this.currentPost
        && this.currentPost
        && this.currentPost.wpml_translations
      ) {
        const currentTranslation = this.checkLanguage(this.value);
        if (currentTranslation) {
          path = currentTranslation.href;
        }
      }
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss">

</style>

<template>
  <main class="main" :class="'main--' + $route.params.slug">
    <div
      :class="{
        'section__wrapper--vertical-centered': $route.params.slug == 'contatti',
      }"
    >
      <PageBuilder
        v-if="page && page.acf.section"
        :sections="page.acf.section"
      />
    </div>
  </main>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { routerLinkWithLang } from "@/assets/js/utils";

import PageBuilder from "@/components/template-parts/PageBuilder";

export default {
  name: "Page",
  components: {
    PageBuilder,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
    postType: {
      type: String,
      default: "pages",
    },
  },
  data() {
    return {
      page: null,
    };
  },
  computed: {
    ...mapState(["site"]),
    ...mapGetters(["lang"]),
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    ...mapActions(["getSingleBySlug", "updateDocTitle"]),
    ...mapMutations(["SET_CURRENT_POST"]),
    loadPage() {
      const request = {
        type: this.postType,
        slug: this.slug,
        lang: this.lang,
        showLoading: true,
      };

      const handleResponse = (response) => {
        if (response) {
          this.page = response;
          this.SET_CURRENT_POST(response);
          this.updateDocTitle({
            parts: [response.title.rendered, this.site.description],
          });
        } else {
          this.$router.push(routerLinkWithLang("/404", this.lang));
        }
      };

      this.getSingleBySlug(request).then((response) => {
        handleResponse(response);
      });
    },
  },
};
</script>

<template>
  <div class="section__tags">
    <button
      @click="filter(item)"
      class="section__button section__button-flex section__button--blue"
      :class="[
        'section__button',
        'section__button-flex',
        `section__button--${theme}`,
        {
          [`section__button--${theme}-active`]: item.term_id === active,
        },
      ]"
      v-for="item in items"
      :key="item.term_id"
    >
      <div>
        <span v-html="item.name"></span>
        <span class="icon__close--white" v-if="item.term_id === active"></span>
      </div>
    </button>
    <div class="spacer"></div>
  </div>
</template>

<script>
export default {
  name: "ArchiveFilter",
  props: {
    items: {
      type: Array,
      required: true,
    },
    theme: {
      type: String,
      default: "blue",
    },
  },
  emits: ["filter"],
  data: () => ({
    active: null,
  }),
  computed: {
    taxonomy() {
      return this.items.length > 0 ? this.items[0].taxonomy : null;
    },
    query() {
      return this.$route.query;
    },
  },
  mounted() {
    if (this.query[this.taxonomy]) {
      const match = this.items.find(
        (item) => item.term_id == this.query[this.taxonomy]
      );
      this.filter(match);
    }
  },
  methods: {
    filter(item) {
      if (this.active === item.term_id) {
        this.active = null;
        this.$router.replace({
          query: {},
        });
      } else {
        this.active = item.term_id;
        if (!this.query[this.taxonomy]) {
          this.$router.replace({
            query: { [this.taxonomy]: item.term_id },
          });
        }
      }
      this.$emit("filter", this.active);
    },
  },
};
</script>

<template>

  <!-- Custom -->
  <div v-if="link.from_link == false">
    <WpLink v-if="link.link" :link="link.link" class="thumb" :class="['thumb--'+link.background_color, 'thumb--'+link.link_style]">
      <div class="thumb__container">
        <div class="thumb__heading">
          <h6 v-html="link.label" v-if="link.label" class="section__label"></h6>
          <h3 v-html="link.title" v-if="$root.addBreakLines(link.title)" class="section__title"></h3>
        </div>
        <div class="thumb__content" v-if="link.rich_text">
          <WpRichText :field="link.rich_text" class="section__rich-text"></WpRichText>
        </div>
      </div>
      <div class="thumb__button" v-html="link.link_label"></div>
    </WpLink>
    <div v-else class="thumb" :class="'thumb--'+link.background_color">
      <div class="thumb__heading">
        <h6 v-html="link.label" v-if="link.label" class="section__label"></h6>
        <h3 v-html="$root.addBreakLines(link.title)" v-if="link.title" class="section__title"></h3>
      </div>
    </div>
  </div>

  <!-- Concerti -->
  <WpLink :link="link.link" v-else-if="post && post.type == 'concerti'" class="thumb thumb--concerti">
    <div class="thumb__heading">
      <div>
        <h6 class="section__label" v-if="post.acf.date"><span v-html="post.acf.date"></span> <span v-if="post.acf.time"> – {{ $labels('ore') }} {{ post.acf.time  }} </span></h6>
        <h6 class="section__label" v-html="post.pure_taxonomies.progetti[0].name" v-else-if="post.pure_taxonomies.progetti && post.pure_taxonomies.progetti[0]"></h6>
        <h5 class="section__title" v-html="$root.addBreakLines(post.title.rendered)"></h5>
        <h5 class="section__subtitle" v-html="$root.addBreakLines(post.acf.subtitle)" v-if="post.acf.subtitle"></h5>
      </div>
      <div v-if="post.acf.city || post.acf.location" class="section__location">
        <span v-html="post.acf.city"></span><span>, {{post.acf.location}}</span>
      </div>
    </div>
    <div class="thumb__button">{{ $labels('scopridipiu') }}</div>
  </WpLink>

  <!-- Dicono di noi -->
  <div v-else-if="post && post.type == 'dicono-di-noi'" class="thumb thumb--dicono-di-noi">
    <div class="thumb__image" v-if="post.better_featured_image">
      <ResponsiveImageVW class="section__image" :wpImageObject="post.better_featured_image"></ResponsiveImageVW>
    </div>
    <h5 v-html="$root.addBreakLines(post.title.rendered)" class="section__title"></h5>
    <a :href="$root.getDiconoDiNoiLink(post)" class="section__button" v-if="link && link.link_to_archive !== true" target="_blank">
      {{ $labels('scopridipiu') }}
    </a>
    <router-link :to="diconoDiNoi" class="section__button"  v-else>
      {{ $labels('scopridipiu') }}
    </router-link>
  </div>

  <!-- Products -->
  <div v-else-if="link && link.price" class="thumb thumb--product" @click="addToCart(link)">
    <div class="thumb__heading">
      <h6 class="section__label"  v-html="link.name"></h6>
      <h5 class="section__title" v-html="link.price_html"></h5>
    </div>
    <div class="section__rich-text" v-html="link.description"></div>
    <div class="thumb__button">{{ $labels('diventa') }} {{link.name}}</div>
  </div>
  
</template>

<script>
import { mapGetters } from 'vuex';
import { routerLinkWithLang } from '@/assets/js/utils'
import { typeSlugAnchorFromPath } from '@/router/utils'

import ResponsiveImageVW from '@/components/utility/ResponsiveImageVW'
import WpLink from '@/components/utility/WpLink'
import WpRichText from '@/components/utility/WpRichText'
import { emptyCart, addToCart  } from '@/api'

export default {
  name: 'Thumb',
  props: {
    postType: {
      type: String,
      required: false
    },
    postSlug: {
      type: String,
      required: false
    },
    link: {
      type: Object,
      required: false
    }
  },
  components: { 
    ResponsiveImageVW,
    WpLink,
    WpRichText
  },
  data() {
    return {
      request: {
        type: this.postType,
        slug: this.postSlug,
        lang: this.$store.state.lang
      },
      hover: false,
      post: null,
    }
  },
  computed: {
    ...mapGetters(['lang', 'defaultLang', 'langs', 'currentPost']),
    // post() {
    //   if (this.link.from_link && this.link.link.url) {
    //     return this.$store.getters.singleBySlug(this.request)
    //   } else {
    //     return this.link
    //   }
    // },
    diconoDiNoi() {
      return routerLinkWithLang('/dicono-di-noi', this.$store.state.lang)
    }
    // link() {
    //   if (this.link.link_to_archive) {
    //     return #
    //   }
    // }
  },
  methods: {
    getPost() {
      return this.$store.dispatch('getSingleBySlug', this.request)
    },
    mouseOver() {
      this.hover = !this.hover;
    },
    async addToCart(product) {
      await emptyCart(this.$cookies)
      await addToCart(product, this.$cookies)
      this.lang === this.defaultLang ? this.$router.push('/checkout') : this.$router.push('/'+this.lang+'/checkout');
    }
  },
  created() {
    if (this.link.from_link && this.link.link.url) {
      const { type, slug } = typeSlugAnchorFromPath(this.link.link.url)
      this.request = {
        type: type,
        slug: slug,
        lang: this.$store.state.lang
      }
      this.getPost().then((response) => {
        this.post = response
      })
      
    }
  }
}
</script>
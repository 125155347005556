<template>
  <div
    class="site-footer"
    v-if="
      $route.name != 'ThankYouPage' &&
      $route.name != '404' &&
      $route.name != 'Checkout'
    "
    :key="lang"
  >
    <!-- && $route.params.slug != 'contatti' -->
    <div class="section__wrapper--full">
      <div class="logo logo--complete-white"></div>
      <div class="site-footer__header">
        <!-- <p class="section__label--small" v-html="$store.state.site.description">La Fil · Filarmonica di Milano</p> -->
        <div
          class="section__label"
          v-html="$store.state.settings[lang].footer_description"
        ></div>
      </div>
      <div class="site-footer__body section__columns section__columns--4">
        <div
          v-for="(list, index) in $store.state.settings[lang].footer_list"
          :key="'list-' + index"
          class="site-footer__block"
        >
          <div v-html="list.label" class="section__label--small"></div>
          <WpRichText :field="list.content" class="section__label"></WpRichText>
        </div>
        <div class="site-footer__block">
          <dt class="section__label--small">{{ $labels("newsletter") }}</dt>
          <div class="section__newsletter">
            <form
              action="https://lafil.us19.list-manage.com/subscribe/post"
              method="get"
              target="_blank"
            >
              <input type="hidden" name="u" value="cb3382536aa50a3ddde26521e" />
              <input type="hidden" name="id" value="8cb6fa8fc1" />
              <input
                type="text"
                name="MERGE0"
                id=""
                :placeholder="$labels('latuamail')"
              />
              <button type="submit" value="Invia">↗</button>
            </form>
          </div>
        </div>
        <dl class="site-footer__block">
          <dt class="section__label--small">{{ $labels("sitemap") }}</dt>
          <NavMenu class="site-footer__menu" name="main" />
        </dl>
        <div>
          <div
            v-for="(list, index) in $store.state.settings[lang].footer_credits"
            :key="'list-' + index"
            :class="{
              'site-footer__block':
                index < $store.state.settings[lang].footer_credits.length - 1,
            }"
          >
            <div v-html="list.label" class="section__label--small"></div>
            <WpRichText
              :field="list.content"
              class="section__label"
            ></WpRichText>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import NavMenu from "@/components/template-parts/NavMenu";
import WpRichText from "@/components/utility/WpRichText";

export default {
  name: "SiteFooter",
  components: {
    NavMenu,
    WpRichText,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["lang", "defaultLang", "langs", "currentPost"]),
  },
  mounted() {},
};
</script>

<template>
<!-- <transition 
      name="fade"
      :duration="1000"
    >
    </transition> -->
  <div class="responsive-image" v-if="type == 'image'">
    <figure
      :id="'ri-figure-' + wpImageObject.id"
      v-if="wpImageObject && type == 'image'"
      class="responsive-figure"
      :class="imageClass">
      <img
        class="responsive-image"
        :srcset="srcset"
        :sizes="sizes"
        :src="wpImageObject.sizes ? wpImageObject.sizes.thumbnail : null"
        :alt="wpImageObject.alt"
        />
        <!-- :title="wpImageObject.title"  -->
      <figcaption v-if="wpImageObject.caption">{{wpImageObject.caption}}</figcaption>
    </figure>
  </div>
  <div
    v-else-if="wpImageObject && type == 'background'"
    class="responsive-image-background"
    :class="imageClass"
    :id="'ri-cover-' + wpImageObject.id"
    >
    <img
      class="background-img"
      :srcset="srcset"
      :sizes="sizes"
      :alt="wpImageObject.alt"
    />
    <!-- :title="wpImageObject.title"  -->
  </div>
</template>

<script>
export default {
  name: 'ResponsiveImageVW',
  props: {
    wpImageObject: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'image',
      required: false,
    },
    direction: {
      type: String,
      default: 'horizontal',
      required: false,
    },
    imageClass: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      request: {
        type: 'media',
        id: this.wpImageObject.id,
        batch: true
      }
    }
  },
  computed: {
    // image() {
    //   return this.$store.getters.singleById(this.request);
    // },
    sizes() {
      return '(max-width: 1200px) 100vw, 1200px';
    },
    srcset() {
      return this.wpImageObject.media_details && this.wpImageObject.media_details.sizes ?
        this.getSrcsetFromThumb() :
        this.getSrcsetFromImage();
    }
  },
  methods: {
    // getMedia() {
    //   if (this.wpImageObject) {
    //     this.$store.dispatch('getSingleById', this.request)
    //   }
    // }
    getSrcsetFromThumb(thumb) {
      const sizes = this.wpImageObject.media_details.sizes;
      
      return Object.keys(sizes)
        .reduce((srcset, size) => {
          if (!isNaN(parseInt(sizes[size].width))) {
            srcset.push(`${sizes[size].source_url} ${sizes[size].width}w`);
          }
          return srcset;
        }, [])
        .join(', ');
    },
    getSrcsetFromImage(image) {
      const sizes = this.wpImageObject.sizes;

      return Object.keys(sizes).filter(size => size.includes("-width"))
        .reduce((srcset, size) => {
          if (!isNaN(parseInt(sizes[size]))) {
            srcset.push(`${sizes[size.substring(0, size.length - 6)]} ${sizes[size]}w`);
          }
          return srcset;
        }, [])
        .join(', ');
    }
  },
  created() {
    // this.getMedia()
  },
}
</script>

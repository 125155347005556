import {
  fetchItems,
  fetchWooCommerceItems,
  fetchSingle,
  fetchSingleById,
  // addToCart,
} from "@/api";

export default {
  getSingleBySlug(
    { getters, commit },
    { type, slug, lang, showLoading = false, store = true }
  ) {
    if (store) {
      const itemFromStore = getters.singleBySlug({ type, slug, lang });
      if (!itemFromStore) {
        if (showLoading) {
          commit("SET_LOADING", true);
        }
        return fetchSingle({ type, params: { slug, lang } }).then(
          ({ data: [item] }) => {
            commit("ADD_ITEM", { type, item, lang });
            if (showLoading) {
              commit("SET_LOADING", false);
            }
            return item;
          }
        );
      } else {
        return itemFromStore;
      }
    } else {
      if (showLoading) {
        commit("SET_LOADING", true);
      }
      return fetchSingle({ type, params: { slug, lang } }).then(
        ({ data: [item] }) => {
          if (showLoading) {
            commit("SET_LOADING", false);
          }
          return item;
        }
      );
    }
  },
  getSingleById(
    { getters, commit },
    { type, id, lang, showLoading = false, batch = false, store = true }
  ) {
    if (store) {
      if (!getters.singleById({ type, id })) {
        if (showLoading) {
          commit("SET_LOADING", true);
        }
        return fetchSingleById({ type, id, batch }).then(({ data }) => {
          if (batch) {
            data.forEach((item) => commit("ADD_ITEM", { type, item, lang }));
          } else {
            commit("ADD_ITEM", { type, item: data, lang });
          }
          if (showLoading) {
            commit("SET_LOADING", false);
          }
        });
      }
    } else {
      if (showLoading) {
        commit("SET_LOADING", true);
      }
      return fetchSingleById({ type, id, batch }).then(({ data }) => {
        if (showLoading) {
          commit("SET_LOADING", false);
        }
        return data;
      });
    }
  },
  getItems(
    { getters, commit },
    { type, params, showLoading = false, store = true }
  ) {
    if (store) {
      if (!getters.request({ type, params })) {
        if (showLoading) {
          commit("SET_LOADING", true);
        }
        return fetchItems({ type, params }).then(
          ({
            data: items,
            headers: { "x-wp-total": total, "x-wp-totalpages": totalPages },
          }) => {
            items.forEach((item) =>
              commit("ADD_ITEM", { type, item, lang: params.lang })
            );
            commit("ADD_REQUEST", {
              type,
              request: {
                params,
                total: parseInt(total),
                totalPages: parseInt(totalPages),
                data: items.map((i) => i.id),
              },
            });
            if (showLoading) {
              commit("SET_LOADING", false);
            }

            return items;
          }
        );
      }
      return getters.requestedItems({ type, params });
    } else {
      if (showLoading) {
        commit("SET_LOADING", true);
      }
      return fetchItems({ type, params }).then(
        ({
          data: items,
          headers: { "x-wp-total": total, "x-wp-totalpages": totalPages },
        }) => {
          if (showLoading) {
            commit("SET_LOADING", false);
          }
          return { items, total, totalPages };
        }
      );
    }
  },
  getWooCommerceItems(
    { getters, commit },
    {
      type,
      params,
      showLoading = false,
      store = true,
      itemsFilterCb = (x) => x,
    }
  ) {
    if (store) {
      if (!getters.request({ type, params })) {
        if (showLoading) {
          commit("SET_LOADING", true);
        }
        return fetchWooCommerceItems({ type, params }).then(
          ({
            data: items,
            headers: { "x-wp-total": total, "x-wp-totalpages": totalPages },
          }) => {
            const filteredItems = items.filter(itemsFilterCb);
            filteredItems.forEach((item) =>
              commit("ADD_ITEM", { type, item, lang: params.lang })
            );
            commit("ADD_REQUEST", {
              type,
              request: {
                params,
                total: parseInt(total),
                totalPages: parseInt(totalPages),
                data: filteredItems.map((i) => i.id),
              },
            });
            if (showLoading) {
              commit("SET_LOADING", false);
            }
            return filteredItems;
          }
        );
      }
      return getters.requestedItems({ type, params });
    } else {
      if (showLoading) {
        commit("SET_LOADING", true);
      }
      return fetchWooCommerceItems({ type, params }).then(
        ({
          data: items,
          headers: { "x-wp-total": total, "x-wp-totalpages": totalPages },
        }) => {
          const filteredItems = items.filter(itemsFilterCb);
          if (showLoading) {
            commit("SET_LOADING", false);
          }
          return filteredItems;
        }
      );
    }
  },
  updateDocTitle({ state, commit }, { parts = [], sep = " – " }) {
    commit("SET_DOC_TITLE", parts.join(sep));
    document.title = state.site.docTitle;
  },
  // addToCart({ commit }, { params, showLoading = false }) {
  //   if (showLoading) {
  //     commit('SET_LOADING', true)
  //   }
  //   return addToCart(params)
  //     .then(data => data.data)
  //     .catch(err => { throw err })
  //     .finally(() => {
  //       if (showLoading) {
  //         commit('SET_LOADING', false)
  //       }
  //     })
  // },
  onWindowResize({ commit }, isMobile) {
    commit("SET_IS_MOBILE", isMobile);
  },
};

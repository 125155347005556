<template>
  <article class="post-item" :class="[ 'post-item--'+post.type , {'active' : isPostItemActive || isPostItemActiveTemp }, {'post-item--no-link' : post.type == 'dicono-di-noi' && !hasLink} ]">

    <!-- Concerti -->
    <router-link
      :to="getConcertoLink(post.link)"
      :title="post.title.rendered"
      class="post-item__link"
      v-if="post.type == 'concerti'"
    >      <div class="post-item__header">
        <div>
          <div class="section__title" v-html="parseDate(post.acf.date)"></div>
          <div class="section__title" v-if="post.acf.time">{{ $labels('ore') }} {{post.acf.time}}</div>
        </div>
        <div>
          <div class="section__title" v-html="post.title.rendered"></div>
          <div class="section__subtitle" v-html="post.acf.subtitle"></div>
        </div>
      </div>
      <div class="post-item__body section__grid section__grid--4">
        <div>
          <p v-if="post.pure_taxonomies.progetti && post.pure_taxonomies.progetti[0]" v-html="post.pure_taxonomies.progetti[0].name"></p>
        </div>
        <div v-if="post.acf.city || post.acf.location">
          <p v-html="post.acf.city"></p>
          <p v-html="post.acf.location"></p>
        </div>
        <div v-if="!$store.getters.isMobile">
          <p v-if="post.acf.attributes && post.acf.attributes[0]" v-html="post.acf.attributes[0].label"></p>
          <p v-if="post.acf.attributes && post.acf.attributes[0]" v-html="post.acf.attributes[0].value"></p>
        </div>
        <div v-if="!$store.getters.isMobile">
          <p v-if="post.acf.attributes && post.acf.attributes[1]" v-html="post.acf.attributes[1].label"></p>
          <p v-if="post.acf.attributes && post.acf.attributes[1]" v-html="post.acf.attributes[1].value"></p>
        </div>
        <!-- <div>{{ $labels('scopridipiu') }} ↗</div> -->
      </div>
    </router-link>

    <!-- Dicono di noi -->
    <component :is="getDiconoDiNoiComponent()" :href="$root.getDiconoDiNoiLink(post)" target="_blank" class="post-item__link" v-if="post.type == 'dicono-di-noi'">
      <div class="post-item__header">
        <div class="section__title" v-html="post.title.rendered"></div>
        <div class="section__subtitle" v-html="post.acf.magazine"></div>
      </div>
      <div class="post-item__body section__grid section__grid--4">
        <div v-if="post.pure_taxonomies.tipologie && post.pure_taxonomies.tipologie[0]">
          <p v-html="post.pure_taxonomies.tipologie[0].name"></p>
        </div>
        <div>
          <p v-html="post.acf.date"></p>
        </div>
        <div v-if="!$store.getters.isMobile"></div>
        <div v-if="!$store.getters.isMobile">
          <p v-html="ctaButton(post.acf.type)" v-if="hasLink"></p>
        </div>
      </div>
    </component>

  </article>
</template>

<script>
import moment from 'moment'

export default {
  name: 'PostItem',
  components: {
    // ResponsiveImage,
  },
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isPostItemActiveTemp: false
    }
  },
  created() {

  },
  computed: {
    isPostItemActive() {
      if (this.$store.getters.internalLink({ url: this.post.link }) == this.$route.fullPath) {
        this.isPostItemActiveTemp = true
        return true
      } else {
        setTimeout(()=>{
          this.isPostItemActiveTemp = false
        }, 400)
      }
    },
    hasLink() {
      return this.$root.getDiconoDiNoiLink(this.post)
    }
  },
  methods: {
    parseDate(date) {
      moment.locale(this.$store.state.lang);
      return moment(date, 'DD.MM.YYYY').format('DD MMMM YYYY')
    },
    ctaButton(type) {
      if (type == "download") {
        return this.$labels('scaricalarticolo')
      } else {
        return this.$labels('continuaaleggere')
      }
    },
    getConcertoLink(link) {
      let to = this.$store.getters.internalLink({ url: link })
      if (this.$route.query.progetto) {
        to += `?progetto=${this.$route.query.progetto}`
      }
      return to
    },
    getDiconoDiNoiComponent() {
      if (this.hasLink) {
        return 'a'
      } else {
        return 'div'
      }
    }
  },
  watch: {

  }
}
</script>

<template>
  <main class="main" :class="'main--' + $route.params.slug">
    <!-- <article v-if="post">
      <header>
        <ResponsiveImage
          v-if="post.featured_media"
          :media-id="post.featured_media"
          :sizes="'(max-width: 1200px) 100vw, 1200px'"
        />
        <h1 v-html="post.title.rendered"></h1>
      </header>
      <WpRichText :field="post.content"></WpRichText>
    </article> -->
  </main>
</template>

<script>
import ResponsiveImage from "@/components/utility/ResponsiveImage";
import WpRichText from "@/components/utility/WpRichText";

export default {
  name: "Single",
  components: {
    ResponsiveImage,
    WpRichText,
  },
  props: {
    postType: {
      type: String,
      required: false,
    },
    slug: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      post: null,
      request: {
        type: this.postType,
        slug: this.slug,
        lang: this.$store.state.lang,
        showLoading: true,
      },
    };
  },
  methods: {
    getPost() {
      this.$store.dispatch("getSingleBySlug", this.request).then((response) => {
        if (response) {
          this.post = response;
          this.$store.commit("SET_CURRENT_POST", this.post);
          this.$store.dispatch("updateDocTitle", {
            parts: [
              this.post.title.rendered,
              this.$store.state.site.description,
            ],
          });
        }
      });
    },
  },
  created() {
    this.post = this.$store.getters.singleBySlug(this.request);
    this.getPost();
  },
};
</script>

import router from "@/router";

export default {
  menu:
    (state) =>
    ({ name }) => {
      return state.menus[state.lang][name];
    },
  request:
    (state) =>
    ({ type, params }) => {
      return state[type].requests.find((req) => {
        if (Object.keys(req.params).length === Object.keys(params).length) {
          return Object.keys(req.params).every(
            (key) => req.params[key] === params[key]
          );
        }
      });
    },
  totalPages:
    (state, getters) =>
    ({ type, params }) => {
      let request = getters.request({ type, params });
      return request ? request.totalPages : 0;
    },
  requestedItems:
    (state, getters) =>
    ({ type, params }) => {
      let request = getters.request({ type, params });
      return request
        ? request.data.map((id) => state[type][state.lang][id])
        : [];
    },
  singleBySlug:
    (state) =>
    ({ type, slug, lang }) => {
      // console.log(state, type, slug, lang);
      // if (slug == "concerti" && lang == "en") {
      //   slug = "concerts"
      // }
      // debugger
      // Check in translated
      // if (!state[type]) {
      //   let originalType = state.i18n.postTypesTranslations[type]
      //   console.log(originalType);
      // }
      for (let id in state[type][lang]) {
        if (decodeURI(state[type][lang][id].slug) === slug) {
          return state[type][lang][id];
        }
      }
    },
  postTypeBySlug:
    (state) =>
    ({ slug }) => {
      for (const key in state.postTypes) {
        if (state.postTypes.hasOwnProperty(key)) {
          if (key == slug) {
            return state.postTypes[key];
          }
        }
      }
    },
  singleById:
    (state) =>
    ({ type, id, lang }) => {
      return state[type][lang][id];
    },
  internalLink:
    (state) =>
    ({ url }) => {
      if (!url) {
        return null;
      }
      return `${url.replace(router.options.base, "/")}`;
    },
  // baseUrl: state => `${state.site.url.replace("8888", '3000')}`,
  isMobile: (state) => state.isMobile,
  prevLang: (state) => state.prevLang,
  lang: (state) => state.lang,
  defaultLang: (state) => state.i18n.default,
  langs: (state) => state.i18n.langs,
  currentPost: (state) => state.currentPost,
};

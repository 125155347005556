import Vue from 'vue';

export default {
  ADD_ITEM(state, { type, item, lang }) {
    if (item && type && lang && ! state[type][lang].hasOwnProperty(item.id)) {
      Vue.set(state[type][lang], item.id, item);
    }
  },
  ADD_REQUEST(state, { type, request }) {
    state[type].requests.push(request);
  },
  SET_LOADING(state, loading) {
    state.site.loading = loading;
  },
  SET_DOC_TITLE(state, title) {
    state.site.docTitle = title;
  },
  SET_IS_MOBILE(state, value) {
    Vue.set(state, "isMobile", value);
  },
  SET_CURRENT_POST(state, value) {
    state.currentPost = value;
  },
  SET_FROM(state, value) {
    state.from = value;
  },
  SET_LANG(state, value) {
    state.prevLang = state.lang
    state.lang = value;
  },
  ADD_LANGUAGES(state, value) {
    value.forEach((lang) => {
      state.pages[lang] = {};

      Object.keys(state.postTypes).forEach((key) => {
        if (key === 'product') {
          state.products[lang] = {};
        } else {
          const restBase = state.postTypes[key].rest_base ? state.postTypes[key].rest_base : key;
          state[restBase][lang] = {};
        }
      });
    });
  },
}

<template>
  <main class="main main--404">
    <section class="section section--1">
      <div class="section__wrapper section__wrapper--vertical-centered">
        <div>
          <div class="section__title">404<br>{{ $labels.paginanontrovata }}</div>
          <router-link :to="home" class="section__button section__button--black-negative">{{ $labels('tornallahome') }}</router-link>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { routerLinkWithLang } from '@/assets/js/utils'
export default {
  data() {
    return {
    }
  },
  computed: {
    home() {
      return routerLinkWithLang('/', this.$store.state.lang)
    },
    
  },
  created() {
    this.$store.dispatch('updateDocTitle', { parts: [ this.$labels.paginanontrovata, this.$store.state.site.description ] })
  }
}
</script>

<template>
  <div
    id="app"
    class="app"
    :class="{ 'app--loading': !loaded }"
    @click="handleClicks"
  >
    <SiteHeader></SiteHeader>
    <transition
      :name="getTransitionName()"
      duration="200"
      appear
      @after-leave="updateScroll"
    >
      <RouterView :key="forceReloadRouter()" />
    </transition>
    <transition
      @enter="enterOverlay"
      @leave="leaveOverlay"
      appear
      :css="false"
      mode="out-in"
    >
      <RouterView
        :key="$route.path"
        class="router-overlay page"
        name="overlay"
      />
    </transition>
    <!-- <transition name="fade">
      <site-loading v-if="loading" />
    </transition> -->
    <SiteFooter
      v-if="
        $route.name != 'ArchiveConcerti' && $route.name != 'ArchiveDiconoDiNoi'
      "
    ></SiteFooter>
  </div>
</template>

<style lang="scss">
@import "scss/app";
</style>

<script>
import gsap from "gsap";
import { mapGetters, mapState } from "vuex";

import SiteHeader from "@/components/template-parts/SiteHeader";
import SiteFooter from "@/components/template-parts/SiteFooter";
import SiteLoading from "@/components/utility/SiteLoading";

gsap.defaults({
  force3D: true,
});

export default {
  components: {
    SiteHeader,
    SiteLoading,
    SiteFooter,
  },
  created() {
    this.$store.dispatch("onWindowResize", window.innerWidth < 1000);
    window.addEventListener("resize", () => {
      this.$store.dispatch("onWindowResize", window.innerWidth < 1000);
    });

    this.$root.slugify = (text) => {
      if (!text) return;
      return text
        .toString()
        .normalize("NFD") // split an accented letter in the base letter and the acent
        .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
        .toLowerCase()
        .trim()
        .replace(/\s+/g, "-")
        .replace(/[^\w\-]+/g, "")
        .replace(/\-\-+/g, "-");
    };
    this.$root.addBreakLines = (text) => {
      return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
    };
    // this.$root.lastTaxonomySelected = {
    //   progetto: null,
    //   tipologia: null
    // }
    this.$root.gallerySetting = {
      wrapAround: true,
      freeScroll: false,
      lazyLoad: false,
      draggable: true,
      pageDots: false,
      imagesLoaded: true,
      arrowShape:
        "M48.13 73.18L27.908 53.213c7.311.46 15.308.46 19.078.46H84v-7.345H46.987c-3.77 0-11.767 0-19.078.459l20.22-19.967L42.874 22 15 50l27.874 28 5.255-4.82z",
    };
    this.$root.getDiconoDiNoiLink = (post) => {
      if (post.acf.type == "link") {
        return post.acf.link;
      }
      if (post.acf.type == "download") {
        return post.acf.file.url;
      }
    };
  },
  mounted() {
    // Remove :hover
    function hasTouch() {
      return (
        "ontouchstart" in document.documentElement ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    }

    if (hasTouch()) {
      // remove all the :hover stylesheets
      try {
        // prevent exception on browsers not supporting DOM styleSheets properly
        for (var si in document.styleSheets) {
          var styleSheet = document.styleSheets[si];
          if (!styleSheet.rules) continue;

          for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
            if (!styleSheet.rules[ri].selectorText) continue;

            if (styleSheet.rules[ri].selectorText.match(":hover")) {
              styleSheet.deleteRule(ri);
            }
          }
        }
      } catch (ex) {}
    }

    this.loaded = true;
  },
  data() {
    return {
      site: this.$store.state.site,
      loaded: false,
      basePath: "",
    };
  },
  computed: {
    ...mapGetters(["lang", "prevLang", "defaultLang", "langs"]),
    ...mapState(["from"]),
    loading() {
      return this.$store.state.site.loading;
    },
  },
  methods: {
    getLinkEl(el) {
      while (el.parentNode) {
        if (el.tagName === "A") return el;
        el = el.parentNode;
      }
    },
    handleClicks(e) {},
    updateScroll() {
      // Vado a un concerto
      if (this.$route.meta && this.$route.meta.isOverlay === true) {
        return;
      }
      // Vado da un concerto all'archivio
      if (
        this.$route.meta &&
        this.$route.meta.isParentWithOverlay === true &&
        this.from &&
        this.from.meta.isOverlay === true
      ) {
        return;
      }
      window.scroll(0, 0);
    },
    getTransitionName() {
      // Vado a un concerto
      if (this.$route.meta && this.$route.meta.isOverlay === true) {
        return;
      }
      // Vado da un concerto all'archivio
      if (
        this.$route.meta &&
        this.$route.meta.isParentWithOverlay === true &&
        this.from &&
        this.from.meta.isOverlay === true
      ) {
        return;
      }
      return "fade";
    },
    forceReloadRouter() {
      if (this.$route.meta.isOverlay) {
        if (this.from && !this.from.meta.isOverlay) {
          this.basePath = this.from.path;
          return this.basePath;
        }
        return this.basePath;
      }

      return this.$route.path;
    },
    enterOverlay(el, done) {
      gsap.set(el, {
        minHeight: window.innerHeight,
        y: "100%",
      });

      gsap
        .to(el, {
          duration: 0.5,
          ease: "expo.out",
          y: "0%",
          clearProps: "all",
        })
        .then(done);
    },
    leaveOverlay(el, done) {
      done();
    },
  },
};
</script>
e
